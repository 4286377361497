import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alignment, Button, Menu, MenuItem, Popover, PopoverPosition } from '@blueprintjs/core';
import { mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import '../../sass/select-dropdown.sass';
export default class SelectDropdown extends React.Component {
    handleSelect(o, i) {
        let { onChange } = this.props;
        if (onChange)
            onChange(o, i);
    }
    render() {
        let { icon, source, value, small, className, buttonClassName, labelField, labelRenderer, allowNull, nullLabel, } = this.props;
        return (_jsxs(Popover, Object.assign({ usePortal: false, className: className, minimal: true, position: PopoverPosition.BOTTOM }, { children: [_jsx(Button, Object.assign({ small: small, className: `select-button ${buttonClassName}`, icon: icon, rightIcon: _jsx(Icon, { path: mdiMenuDown, size: '16px' }, void 0), alignText: Alignment.LEFT }, { children: value !== undefined && value !== null
                        ? labelField
                            ? value[labelField]
                            : labelRenderer
                                ? labelRenderer(value)
                                : `${value}`
                        : nullLabel }), void 0), _jsxs(Menu, Object.assign({ className: 'select-menu' }, { children: [allowNull ? (_jsx(MenuItem, { text: nullLabel, onClick: () => this.handleSelect(null, -1) }, 'dd-null')) : null, source === null || source === void 0 ? void 0 : source.map((o, i) => (_jsx(MenuItem, { text: labelField ? o[labelField] : labelRenderer ? labelRenderer(o) : `${o}`, onClick: () => this.handleSelect(o, i) }, `dd-${i}`)))] }), void 0)] }), void 0));
    }
}
SelectDropdown.defaultProps = {
    className: '',
    buttonClassName: '',
    nullLabel: '[No value]',
};
