import { LG } from 'big-l';
import { HTTPClient } from '../..';
import { HTTPError } from '../../net/HTTPClient';
import { error, load } from './thunks';
import { AuthErrorType } from './types';
const LGR = LG.ns('AuthMiddleware');
export const authMiddleware = (store) => next => {
    let initialized = false;
    return (action) => {
        if (!initialized) {
            LGR.debug('Initialize middleware');
            initialized = true;
            HTTPClient.addTokenListener(_ => {
                LGR.debug('Load auth');
                store.dispatch(load());
            }, false);
            HTTPClient.addErrorListener(err => {
                LGR.warn('Middleware HTTPError', err === null || err === void 0 ? void 0 : err.code);
                switch (err === null || err === void 0 ? void 0 : err.code) {
                    case HTTPError.UNAUTHORIZED:
                        store.dispatch(error(AuthErrorType.WRONG_CREDENTIALS));
                        break;
                    case HTTPError.FORBIDDEN:
                        store.dispatch(error(AuthErrorType.NOT_ALLOWED));
                        break;
                }
            });
        }
        return next(action);
    };
};
