"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemType = void 0;
var SystemType;
(function (SystemType) {
    SystemType["KATAL"] = "katal";
    SystemType["UNIMAG"] = "unimag";
    SystemType["METI"] = "meti";
    SystemType["MOCKUP"] = "mockup";
})(SystemType = exports.SystemType || (exports.SystemType = {}));
