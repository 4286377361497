export const EXT = "xlsx";
export var CellType;
(function (CellType) {
    CellType["NUMBER"] = "n";
    CellType["TEXT"] = "s";
    CellType["DATE"] = "d";
})(CellType || (CellType = {}));
export var CellFormat;
(function (CellFormat) {
    CellFormat["PERCENT"] = "0.00 %";
    CellFormat["PERCENT_OFFEST"] = "+0.00 %;-0.00 %";
    CellFormat["EURO"] = "0.00 \u20AC";
    CellFormat["EURO_OFFEST"] = "+0.00 \u20AC;-0.00 \u20AC";
    CellFormat["INTEGER"] = "0";
    CellFormat["NUMERIC"] = "0.00";
})(CellFormat || (CellFormat = {}));
export var Color;
(function (Color) {
    Color["RED"] = "Red";
    Color["GREEN"] = "Green";
    Color["WHITE"] = "White";
    Color["BLACK"] = "Black";
    Color["BLUE"] = "Blue";
    Color["YELLOW"] = "Yellow";
    Color["MAGENTA"] = "Magenta";
    Color["CYAN"] = "Cyan";
})(Color || (Color = {}));
export function colorize(format, negative = Color.RED, positive, zero) {
    return `${positive ? "[" + positive + "]" : ""}${format};${negative ? "[" + negative + "]" : ""}-${format};${zero ? "[" + zero + "]" : ""}0`;
}
