"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Template = void 0;
var Logger_1 = require("../debug/Logger");
var Template;
(function (Template) {
    var Operator;
    (function (Operator) {
        Operator.ARGS_SEPARATOR = ",,";
        var childrenValue = function (next, matches, fallback, prefix) {
            if (matches.length) {
                return next(matches.map(function (m, i) { return (__assign(__assign({}, m), { before: i == 0 ? m.before.slice(prefix.length) : m.before })); }));
            }
            else {
                return fallback;
            }
        };
        Operator.Noop = {
            symbol: "-",
            compute: function (mtch, getValue, next) {
                return childrenValue(next, mtch.children, mtch.content, "");
            },
        };
        Operator.Parameter = {
            symbol: ":",
            compute: function (mtch, getValue) {
                var val = getValue(mtch.content);
                // Logger.debug("Value for", mtch.content, "is", val);
                return val;
            },
        };
        Operator.Conditional = {
            symbol: "?",
            compute: function (mtch, getValue, next) {
                var _a = mtch.content.split(Operator.ARGS_SEPARATOR), cond = _a[0], res = _a.slice(1);
                var condvalue = getValue(cond);
                Logger_1.Logger.notice("Check", cond, condvalue);
                if (condvalue !== undefined && condvalue !== false && condvalue !== null)
                    return childrenValue(next, mtch.children, res.join(""), cond + Operator.ARGS_SEPARATOR);
                return null;
            },
        };
        Operator.Not = {
            symbol: "!",
            compute: function (mtch, getValue, next) {
                var _a = mtch.content.split(Operator.ARGS_SEPARATOR), cond = _a[0], res = _a.slice(1);
                var condvalue = getValue(cond);
                if (condvalue === undefined || condvalue === false || condvalue === null)
                    return childrenValue(next, mtch.children, res.join(""), cond + Operator.ARGS_SEPARATOR);
                return null;
            },
        };
        Operator.Lazy = {
            symbol: "|",
            compute: function (mtch, getValue, next) {
                var _a = mtch.content.split(Operator.ARGS_SEPARATOR), cond = _a[0], res = _a.slice(1);
                var condvalue = getValue(cond);
                if (condvalue === undefined || condvalue === false || condvalue === null)
                    return childrenValue(next, mtch.children, res.length ? res.join("") : null, cond + Operator.ARGS_SEPARATOR);
                return condvalue;
            },
        };
    })(Operator = Template.Operator || (Template.Operator = {}));
    var DEFAULT_OPERATORS = [Operator.Noop, Operator.Parameter, Operator.Not, Operator.Conditional, Operator.Lazy];
    var defaultMatchOptions = {
        start: "{",
        end: "}",
        operators: DEFAULT_OPERATORS.map(function (op) { return op.symbol; }),
    };
    var defaultReplaceOptions = __assign(__assign({}, defaultMatchOptions), { operators: __spreadArrays(DEFAULT_OPERATORS), separator: "||" });
    var internalGetValue = function (field, params, cache) {
        var value = cache[field];
        if (value !== undefined)
            return value;
        if (value === undefined) {
            var currentValue = params;
            var path = field.split(".");
            while (path.length && typeof currentValue == "object") {
                currentValue = currentValue[path.shift()];
            }
            value = cache[field] = currentValue;
        }
        // if (value === undefined) {
        // 	Logger.warn(field, "not found");
        // } else {
        // 	Logger.verb(field, "is", value);
        // }
        return value;
    };
    var internalCompute = function (matches, params, operators, cache) {
        // Logger.debug("Compute", matches);
        var values = matches.map(function (match) {
            var _a;
            var operatorCompute = (_a = operators[match.operator]) === null || _a === void 0 ? void 0 : _a.compute;
            if (!operatorCompute || !match.end)
                return match.source;
            var computed = operatorCompute(match, function (name) { return internalGetValue(name, params, cache); }, function (childrenMatches) { return internalCompute(childrenMatches, params, operators, cache); });
            var computedValue = computed !== undefined
                ? match.before || match.after
                    ? match.before + (computed !== null ? computed : "") + match.after
                    : computed
                : match.source + match.after;
            // Logger.debug(match, "=>", computed, "=>", computedValue);
            return computedValue;
        });
        // Logger.debug("values =>", values);
        values = values.filter(function (v) { return v !== undefined && v !== null; });
        switch (values.length) {
            case 0:
                return null;
            case 1:
                return values[0];
            default:
                return values.join("");
        }
    };
    function matchAll(str, options) {
        var _a;
        var _b = __assign(__assign({}, defaultMatchOptions), options), start = _b.start, end = _b.end, operators = _b.operators;
        var stack = [], found = [];
        var position = 0, beforeContent = "", pending = str, currentLevel, lastClosedLevel;
        var operatorList = __spreadArrays(operators).sort(function (o1, o2) { return (o1.length > o2.length ? -1 : 1); });
        while (pending.length) {
            var performedChars = pending.charAt(0);
            // LGR.debug("Test", JSON.stringify(pending));
            var isOpeningTag = false, closingLevel = void 0;
            switch (true) {
                // Found opening tag
                case pending.startsWith(start):
                    for (var _i = 0, operatorList_1 = operatorList; _i < operatorList_1.length; _i++) {
                        var op = operatorList_1[_i];
                        if (pending.startsWith(op, start.length)) {
                            isOpeningTag = true;
                            performedChars = start + op;
                            if (currentLevel)
                                stack.push(currentLevel);
                            var newLevel = {
                                source: beforeContent,
                                before: beforeContent,
                                start: position,
                                end: undefined,
                                level: stack.length,
                                operator: op,
                                content: "",
                                children: [],
                                after: "",
                            };
                            currentLevel = newLevel;
                            if (!newLevel.level)
                                found.push(newLevel);
                            // Found an open operator
                            break;
                        }
                    }
                    break;
                // Found closing tag
                case currentLevel && pending.startsWith(currentLevel.operator + end):
                    performedChars = currentLevel.operator + end;
                    closingLevel = currentLevel;
                    break;
            }
            var isPerformingTag = isOpeningTag || !!closingLevel;
            if (isPerformingTag) {
                beforeContent = "";
            }
            else {
                beforeContent += performedChars;
                if (lastClosedLevel)
                    lastClosedLevel.after += performedChars;
            }
            for (var _c = 0, _d = __spreadArrays(stack, [currentLevel]); _c < _d.length; _c++) {
                var lvl = _d[_c];
                if (lvl) {
                    lvl.source += performedChars;
                    if (!isPerformingTag || lvl !== currentLevel) {
                        lvl.content += performedChars;
                    }
                }
            }
            if (isOpeningTag) {
                // Logger.verb("Opening tag", performedChars, `@${position}`);
                lastClosedLevel = undefined;
            }
            if (closingLevel) {
                // Logger.verb("Closing tag", performedChars, `@${position}`);
                lastClosedLevel = undefined;
                closingLevel.end = position + performedChars.length;
                lastClosedLevel = closingLevel;
                currentLevel = stack.pop();
                if (currentLevel) {
                    currentLevel.children.push(closingLevel);
                    currentLevel.children.map(function (m) { return (m.after = ""); });
                    currentLevel.after = "";
                }
                else {
                    found.map(function (m) { return (m.after = ""); });
                }
            }
            position += performedChars.length;
            pending = str.slice(position);
        }
        var pendingLevels = found.filter(function (m) { return m.end === undefined; });
        if (pendingLevels.length) {
            Logger_1.Logger.warn("Unbalanced tags", (_a = pendingLevels.shift()) === null || _a === void 0 ? void 0 : _a.source);
            return [];
        }
        return found;
    }
    Template.matchAll = matchAll;
    function replace(stringOrObject, params, options) {
        return internalReplace(stringOrObject, params, __assign(__assign({}, defaultReplaceOptions), options), {});
    }
    Template.replace = replace;
    function internalReplace(stringOrObject, params, options, cache) {
        var operators = options.operators;
        var operatorRegistry = operators.reduce(function (red, op) {
            var _a;
            return (__assign(__assign({}, red), (_a = {}, _a[op.symbol] = op, _a)));
        }, {});
        switch (typeof stringOrObject) {
            case "string": {
                // Logger.debug("Replace in string", stringOrObject);
                var matches = matchAll(stringOrObject, __assign(__assign({}, options), { operators: operators.map(function (op) { return op.symbol; }) }));
                return matches.length ? internalCompute(matches, params, operatorRegistry, cache) : stringOrObject;
            }
            case "object": {
                // Logger.info("Replace in object", stringOrObject);
                switch (true) {
                    case stringOrObject === null:
                        return null;
                    case Array.isArray(stringOrObject):
                        return stringOrObject.map(function (arrayElement) {
                            return internalReplace(arrayElement, params, options, cache);
                        });
                    case stringOrObject instanceof Date:
                    case stringOrObject instanceof RegExp:
                    case stringOrObject instanceof Function:
                        return stringOrObject;
                    default: {
                        // Logger.debug("Replace in object", stringOrObject);
                        var replacedObject = {};
                        for (var objectKey in stringOrObject) {
                            var keyValue = stringOrObject[objectKey];
                            replacedObject[objectKey] = internalReplace(keyValue, params, options, cache);
                        }
                        return replacedObject;
                    }
                }
            }
            default:
                // Logger.info("Don't replace", stringOrObject);
                return stringOrObject;
        }
    }
    Template.internalReplace = internalReplace;
})(Template = exports.Template || (exports.Template = {}));
