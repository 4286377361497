"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeUtils = void 0;
var TimeUtils;
(function (TimeUtils) {
    function waitFor(timeInMs) {
        return new Promise(function (res) { return setTimeout(res, timeInMs); });
    }
    TimeUtils.waitFor = waitFor;
})(TimeUtils = exports.TimeUtils || (exports.TimeUtils = {}));
