var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class PromisePool {
    constructor(size) {
        // Pooled promises
        this.promises = [];
        this.size = size;
    }
    // Add a promise to pool
    // Return a promised resolved when a new spot is available
    add(p) {
        // Pool not full
        if (this.promises.length < this.size) {
            let { promises } = this;
            // Created a "pooled" promise
            // which will remove itself from the pool when resolved or rejected
            let pooled = p
                .then(_ => {
                // Remove resolved promise from pool
                let pi = promises.findIndex(o => o.promise == p);
                if (pi >= 0) {
                    promises.splice(pi, 1);
                }
                else {
                    throw new Error('[PromisePool] Cannot find promise');
                }
                return _;
            })
                .catch(e => {
                // Remove rejected promise from pool
                // and throw error
                let pi = promises.findIndex(o => o.promise == p);
                if (pi >= 0) {
                    promises.splice(pi, 1);
                }
                else {
                    throw new Error('[PromisePool] Cannot find promise');
                }
                throw e;
            });
            this.promises.push({ pooled, promise: p });
            return this.promises.length >= this.size
                ? Promise.race(this.promises.map(o => o.pooled))
                : Promise.resolve();
        }
        else {
            throw new Error(`[PromisePool] PromisePool is full (${this.size} max)`);
        }
    }
    get count() {
        return this.promises.length;
    }
    get running() {
        return [...this.promises.map(o => o.promise)];
    }
    get isRunning() {
        return !!this.promises.length;
    }
    // When all planned promised were pushed
    // await for the last running promises to complete
    end() {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.all(this.promises.map(o => o.pooled));
        });
    }
}
