import { LG } from 'big-l';
const LGR = LG.ns('WebWorker');
export var WebWorker;
(function (WebWorker) {
    let WorkerFeedbackType;
    (function (WorkerFeedbackType) {
        WorkerFeedbackType["ACK"] = "worker.ack";
        WorkerFeedbackType["LOG"] = "worker.log";
        WorkerFeedbackType["POLL"] = "worker.poll";
        WorkerFeedbackType["RESULT"] = "worker.result";
        WorkerFeedbackType["ERROR"] = "worker.error";
    })(WorkerFeedbackType = WebWorker.WorkerFeedbackType || (WebWorker.WorkerFeedbackType = {}));
    function define(target, method, handler, poll) {
        const worker = target;
        initWorker(worker);
        worker.__workerHhandlers[method] = { handler, poll };
    }
    WebWorker.define = define;
    function initWorker(worker) {
        if (!worker.__workerHhandlers) {
            worker.__workerHhandlers = {};
            worker.addEventListener('message', ({ data: incomingCommand }) => {
                const workerMethod = incomingCommand.type, { handler, poll } = worker.__workerHhandlers[workerMethod];
                if (!!handler) {
                    worker.postMessage({
                        id: incomingCommand.id,
                        method: workerMethod,
                        type: WorkerFeedbackType.ACK,
                        args: incomingCommand.args,
                    });
                    let pollInterval;
                    if (poll) {
                        pollInterval = setInterval(() => worker.postMessage({ id: incomingCommand.id, type: WorkerFeedbackType.POLL }));
                    }
                    handler(...incomingCommand.args)
                        .then(result => worker.postMessage({ id: incomingCommand.id, type: WorkerFeedbackType.RESULT, result }))
                        .catch(error => worker.postMessage({ id: incomingCommand.id, type: WorkerFeedbackType.ERROR, error }))
                        .finally(() => clearInterval(pollInterval));
                }
                else {
                    worker.postMessage({
                        id: incomingCommand.id,
                        type: WorkerFeedbackType.ERROR,
                        error: `No method ${workerMethod} in worker`,
                    });
                }
            });
            worker.console = Object.assign({ log: (...args) => worker.postMessage({ id: 0, level: 'info', type: WorkerFeedbackType.LOG, message: args }) }, ['info', 'debug', 'warn', 'error'].reduce((red, lvl) => (Object.assign(Object.assign({}, red), { [lvl]: (...args) => worker.postMessage({
                    id: 0,
                    level: lvl,
                    type: WorkerFeedbackType.LOG,
                    message: args,
                }) })), {}));
        }
    }
    class Client {
        constructor(url) {
            this.calls = {};
            this.worker = new Worker(url);
            this.worker.addEventListener('message', ({ data: threadMessage }) => {
                const callback = this.getCallbacks(threadMessage.id);
                if (callback) {
                    switch (threadMessage.type) {
                        case WorkerFeedbackType.POLL:
                            LGR.debug('Poll from webworker');
                            break;
                        case WorkerFeedbackType.ERROR:
                            const { reject } = callback;
                            const { error } = threadMessage;
                            reject === null || reject === void 0 ? void 0 : reject(error);
                            break;
                        case WorkerFeedbackType.RESULT:
                            const { resolve } = callback;
                            const { result } = threadMessage;
                            resolve(result);
                            break;
                        default:
                            LGR.debug('Got message', threadMessage);
                            break;
                    }
                }
                else {
                    if (threadMessage.type == WorkerFeedbackType.LOG) {
                        const { level, message } = threadMessage;
                        console[level](...message);
                    }
                }
            });
        }
        getCallbacks(id) {
            return this.calls[id];
        }
        call(method, ...args) {
            LGR.debug('Call', `${this.constructor.name}.${method}`, ...args);
            const id = Client.ID++, promise = new Promise((resolve, reject) => {
                this.calls[id] = { resolve, reject };
            });
            this.worker.postMessage({ id, type: method, args });
            return promise;
        }
    }
    Client.ID = 1;
    WebWorker.Client = Client;
})(WebWorker || (WebWorker = {}));
