"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateUtils = void 0;
var date_fns_1 = require("date-fns");
var DateUtils;
(function (DateUtils) {
    var Format;
    (function (Format) {
        Format.ISO_TZ = "yyyy-MM-dd'T'HH:mm:ss.SSSXX";
        Format.MYSQL_DATE = "yyyy-MM-dd";
        Format.MYSQL_DATETIME = Format.MYSQL_DATE + " HH-mm-ss";
    })(Format = DateUtils.Format || (DateUtils.Format = {}));
    function formatISOTZ(date) {
        if (!date)
            return date;
        return date_fns_1.format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXX");
    }
    DateUtils.formatISOTZ = formatISOTZ;
    function parseISOTZ(str) {
        return date_fns_1.parse(str, "yyyy-MM-dd'T'HH:mm:ss.SSSXX", new Date());
    }
    DateUtils.parseISOTZ = parseISOTZ;
    function valid(dte, replace) {
        return date_fns_1.isValid(dte) ? dte : replace;
    }
    DateUtils.valid = valid;
})(DateUtils = exports.DateUtils || (exports.DateUtils = {}));
