var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, InputGroup, Intent } from '@blueprintjs/core';
import { mdiCloseCircleOutline, mdiWeb } from '@mdi/js';
import Axios from 'axios';
import { LG } from 'big-l';
import classNames from 'classnames';
import highlightjs from 'highlight.js';
import jsonLang from 'highlight.js/lib/languages/json';
import htmlLang from 'highlight.js/lib/languages/xml';
import { indent } from 'indent.js';
import { HTTPClient } from 'katal3-client';
import numeral from 'numeral';
import { Component } from 'react';
import Highlight from 'react-highlight';
import IKon from '../IKon';
highlightjs.registerLanguage('json', jsonLang);
highlightjs.registerLanguage('html', htmlLang);
const LGR = LG.ns('HTTPTab');
const defaultHTTPTabState = {
    httpRunning: false,
    httpPath: '',
};
const MAX_ARRAY_PREVIEW = 2;
class HTTPTab extends Component {
    constructor() {
        super(...arguments);
        this.state = defaultHTTPTabState;
    }
    handleHTTP() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            this.abortHTTP();
            const { httpPath } = this.state;
            this.setState({ httpRunning: true, httpStatus: { Loading: '...' }, httpResult: undefined });
            const sendTime = new Date().getTime();
            this.itv = setInterval(() => this.setState({ httpStatus: { Chargement: `${(new Date().getTime() - sendTime) / 1000}s.` } }), 500 + Math.round(Math.random() * 300));
            this.cancelToken = Axios.CancelToken.source();
            let resp;
            try {
                resp = yield HTTPClient.get(httpPath, { cancelToken: this.cancelToken.token });
                const httpStatus = {
                    'Status': `${resp.statusText} (${resp.status})`,
                    'Response time': `${(resp.stats
                        ? resp.stats.responseTime - resp.stats.requestTime
                        : new Date().getTime() - sendTime) / 1000} s.`,
                    'Size': numeral(((_a = resp.stats) === null || _a === void 0 ? void 0 : _a.responseSize) || JSON.stringify(resp.data).length).format('0.00b'),
                }, httpResult = resp.data;
                if ((_b = resp.stats) === null || _b === void 0 ? void 0 : _b.resultTime)
                    httpStatus['Parse time'] = `${(resp.stats.resultTime - resp.stats.responseTime) / 1000} s.`;
                this.setState({ httpStatus, httpResult });
            }
            catch (e) {
                LGR.error(JSON.stringify(e, null, 2));
                this.setState({
                    httpStatus: e.response
                        ? { Statut: `${e.response.statusText} (${e.response.status})` }
                        : { Error: e.message },
                });
            }
            finally {
                clearInterval(this.itv);
            }
        });
    }
    abortHTTP() {
        if (this.cancelToken)
            this.cancelToken.cancel();
        clearInterval(this.itv);
        this.setState({ httpRunning: false, httpStatus: undefined });
    }
    render() {
        const { httpPath, httpStatus, httpResult } = this.state;
        const json = !!httpResult && typeof httpResult === 'object';
        const html = !!httpResult && typeof httpResult === 'string' && httpResult.charAt(0) === '<';
        return (_jsxs("div", Object.assign({ className: 'http' }, { children: [_jsxs("div", Object.assign({ className: 'http-input' }, { children: [_jsx("label", { children: HTTPClient.server }, void 0), _jsx(InputGroup, { fill: true, value: httpPath, id: 'http-input', placeholder: '/api', onChange: evt => this.setState({ httpPath: evt.currentTarget.value }), onKeyDown: evt => {
                                if (evt.key.toUpperCase() === 'ENTER')
                                    this.handleHTTP();
                            } }, void 0), _jsx(Button, Object.assign({ onClick: () => this.handleHTTP(), disabled: !httpPath.length, icon: _jsx(IKon, { icon: mdiWeb, size: 16 }, void 0), intent: Intent.PRIMARY }, { children: "Send" }), void 0)] }), void 0), httpStatus ? (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: 'http-status' }, { children: [_jsx("div", Object.assign({ className: 'detail' }, { children: Object.keys(httpStatus).map((k, i) => (_jsxs("div", { children: [k, ": ", httpStatus[k]] }, `res${i}`))) }), void 0), _jsx("div", Object.assign({ className: 'controls' }, { children: _jsx(Button, { icon: _jsx(IKon, { icon: mdiCloseCircleOutline, size: 14 }, void 0), onClick: () => {
                                            this.abortHTTP();
                                        } }, void 0) }), void 0)] }), void 0), httpResult ? (_jsx("div", Object.assign({ className: 'http-result' }, { children: _jsx(Highlight, Object.assign({ className: classNames({ json, html }) }, { children: json
                                    ? JSON.stringify(Array.isArray(httpResult)
                                        ? httpResult.length > MAX_ARRAY_PREVIEW
                                            ? [...httpResult.slice(0, MAX_ARRAY_PREVIEW), '...']
                                            : httpResult
                                        : httpResult, null, 2)
                                    : html
                                        ? indent.html(httpResult, { tabString: '  ' })
                                        : httpResult }), void 0) }), void 0)) : null] }, void 0)) : undefined] }), void 0));
    }
}
export default HTTPTab;
