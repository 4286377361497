import { LG } from 'big-l';
import { Excel } from 'katal3-lib';
import { WebWorker } from './WebWorker';
const LGR = LG.ns('ExcelClient');
export class ExcelClient extends WebWorker.Client {
    constructor() {
        super('/workers/excel.js');
    }
    buffer(dataOrSheets, columns, singleSheetTitle) {
        const computed = Excel.compute(dataOrSheets, columns, singleSheetTitle);
        LGR.debug('Got', dataOrSheets, columns, singleSheetTitle);
        LGR.verb('Send', computed);
        return this.call('buffer', computed);
    }
}
