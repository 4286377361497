"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseData = void 0;
var Article_1 = require("./data/Article");
var History_1 = require("./data/History");
var IO_1 = require("./data/IO");
var Log_1 = require("./data/Log");
var Memo_1 = require("./data/Memo");
var Order_1 = require("./data/Order");
var System_1 = require("./data/System");
var BaseData;
(function (BaseData) {
    var Article;
    (function (Article) {
        Article.Status = Article_1.ArticleStatus;
    })(Article = BaseData.Article || (BaseData.Article = {}));
    var History;
    (function (History) {
        History.PeriodType = History_1.HistoryPeriodType;
    })(History = BaseData.History || (BaseData.History = {}));
    var IO;
    (function (IO) {
        IO.Type = IO_1.IOType;
        IO.Status = IO_1.IOStatus;
    })(IO = BaseData.IO || (BaseData.IO = {}));
    var Log;
    (function (Log) {
        Log.Level = Log_1.LogLevel;
    })(Log = BaseData.Log || (BaseData.Log = {}));
    var Order;
    (function (Order) {
        Order.Status = Order_1.OrderStatus;
    })(Order = BaseData.Order || (BaseData.Order = {}));
    var Memo;
    (function (Memo) {
        Memo.Type = Memo_1.MemoType;
    })(Memo = BaseData.Memo || (BaseData.Memo = {}));
    var System;
    (function (System) {
        System.Type = System_1.SystemType;
    })(System = BaseData.System || (BaseData.System = {}));
})(BaseData = exports.BaseData || (exports.BaseData = {}));
