var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Axios from "axios";
import { LG, LO } from "big-l";
import json5 from "json5";
const LGR = LG.ns("HTTPClient");
const TOKEN_LOCAL_KEY = "katal-token";
const JSON_HEADER_KEY = "K-Json";
const STATS_HEADER_KEY = "K-Stats";
const PROTOCOL = "http";
export class HTTPError extends Error {
    constructor(err) {
        var _a, _b, _c, _d;
        super((err === null || err === void 0 ? void 0 : err.message) || "HTTP Error");
        console.warn("error", err);
        this.code = ((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) || -1;
        this.url = (_d = (_c = (_b = err === null || err === void 0 ? void 0 : err.response) === null || _b === void 0 ? void 0 : _b.request) === null || _c === void 0 ? void 0 : _c.config) === null || _d === void 0 ? void 0 : _d.url;
    }
}
HTTPError.UNAUTHORIZED = 401;
HTTPError.FORBIDDEN = 403;
export class HTTPClient {
    static createAxiosClient() {
        let ax = Axios.create();
        ax.defaults.validateStatus = (status) => true;
        ax.interceptors.request.use((req) => {
            if (this._token)
                req.headers = Object.assign(Object.assign({}, req.headers), { Authorization: `Bearer ${this._token}` });
            if (req.responseType === undefined) {
                req.headers = Object.assign(Object.assign({}, req.headers), { [JSON_HEADER_KEY]: true, [STATS_HEADER_KEY]: new Date().getTime() });
                req.responseType = "text";
                req.transformResponse = (o) => o;
            }
            return req;
        });
        ax.interceptors.response.use((res) => __awaiter(this, void 0, void 0, function* () {
            // LGR.debug('Got response from', res.config.url, res.data);
            switch (res.status) {
                case HTTPError.UNAUTHORIZED:
                    HTTPClient.setToken(null);
                    break;
            }
            if (res.status >= HTTPClient.ERROR_LEVEL)
                HTTPClient.dispatchError(new HTTPError({ response: res }));
            const sendTime = res.config.headers[STATS_HEADER_KEY];
            const stats = {
                requestTime: sendTime,
                responseTime: new Date().getTime(),
            };
            if (typeof res.data === "string")
                stats.responseSize = res.data.length;
            if (res.config.headers[JSON_HEADER_KEY] && typeof res.data === "string") {
                const reqUrl = res.config.url;
                // if (reqUrl?.endsWith("taro/articles")) {
                //     const obj = JSON.parse(res.data)[15];
                //     LGR.debug("@15 =>", obj, obj.systems?.unimag?.reference);
                //     LGR.debug("JSON", parseJSON(obj.systems?.unimag?.reference));
                //     LGR.debug("ISO", parseISO(obj.systems?.unimag?.reference));
                //     // LGR.debug("JSON", JSON.parse(res.data).logs?.[484]);
                //     // LGR.debug("LO", LO.parse(res.data, { parseDates: true }).logs?.[484]);
                // }
                res.data = LO.parse(res.data, { parseDates: true });
            }
            stats.resultTime = new Date().getTime();
            return Object.assign(Object.assign({}, res), { stats });
        }), (err) => {
            return Promise.reject(new HTTPError(err));
        });
        return ax;
    }
    static dispatchError(err) {
        for (let listener of HTTPClient.errorListeners)
            listener(err);
    }
    static setToken(tok, silent = false) {
        if (HTTPClient._token !== tok) {
            LG.debug("Set token", tok, silent ? "(in silence)" : undefined);
            if (tok !== null && tok !== undefined) {
                localStorage.setItem(TOKEN_LOCAL_KEY, tok);
            }
            else {
                localStorage.removeItem(TOKEN_LOCAL_KEY);
            }
            HTTPClient._token = tok;
            if (!silent)
                for (let h of this.tokenListeners)
                    h(tok);
        }
    }
    static get token() {
        HTTPClient._token = HTTPClient._token || localStorage.getItem(TOKEN_LOCAL_KEY);
        return HTTPClient._token;
    }
    static get server() {
        return HTTPClient.host
            ? `${HTTPClient.protocol}://${HTTPClient.host}${!isNaN(HTTPClient.port || NaN) ? ":" + HTTPClient.port : ""}${HTTPClient.path}`
            : "";
    }
    static addTokenListener(handler, fire = true) {
        HTTPClient.tokenListeners.push(handler);
        if (fire)
            handler(HTTPClient.token);
    }
    static addErrorListener(handler) {
        HTTPClient.errorListeners.push(handler);
    }
    static getLocalConfig() {
        return this.get("./config.json5", { responseType: "text" })
            .then((result) => {
            const data = json5.parse(result.data);
            LGR.debug("Data", data);
            const { api } = data, config = __rest(data, ["api"]);
            let { host, port, path, protocol } = api;
            if (typeof api === "string")
                host = api;
            LGR.debug("API", api);
            HTTPClient.protocol = ["https", "http2"].includes(protocol || "")
                ? "https"
                : protocol
                    ? "http"
                    : window.location.protocol.slice(0, -1);
            HTTPClient.host = host || window.location.hostname || "localhost";
            HTTPClient.port = port || parseInt(window.location.port, 10);
            HTTPClient.path = path || "";
            LGR.debug(HTTPClient.host, HTTPClient.port, "=>", HTTPClient.server);
            return config;
        })
            .catch((e) => {
            LGR.error("LocalConfig error", e);
            return null;
        });
    }
    static get(url, config) {
        return this.axios.get(HTTPClient.server + url, config);
    }
    static post(url, data, config) {
        return this.axios.post(HTTPClient.server + url, data, config);
    }
    static put(url, data, config) {
        return this.axios.put(HTTPClient.server + url, data, config);
    }
    static patch(url, data, config) {
        return this.axios.patch(HTTPClient.server + url, data, config);
    }
    static delete(url, config) {
        return this.axios.delete(HTTPClient.server + url, config);
    }
}
HTTPClient.axios = HTTPClient.createAxiosClient();
HTTPClient.ERROR_LEVEL = 400;
HTTPClient.protocol = "http";
HTTPClient.host = "";
HTTPClient.path = "";
HTTPClient.tokenListeners = [];
HTTPClient.errorListeners = [];
