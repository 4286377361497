// import { AuthDialog } from 'katal3-client';
import { Card, Menu, MenuItem } from "@blueprintjs/core";
import { LG } from "big-l";
import { Auth } from "katal3-client";
import { AppLayout } from "katal3-react";
import React, { Component, ErrorInfo } from "react";
import { connect, ConnectedProps } from "react-redux";
import DriverSVG from "../driver.svg";
import "./splash.sass";
import { SplashState } from "./state/types";
const LGR = LG.ns("Splash");

type SplashProps = ConnectedProps<typeof connector>;

class Splash extends Component<SplashProps> {
    componentDidMount() {
        this.props.module();
    }

    shouldComponentUpdate(nextProps: SplashProps) {
        return true;
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        LG.error("React error", error.message, errorInfo);
    }

    render = () => {
        let { user } = this.props;
        const test = new Array(1500).fill(0).map((v, i) => ({ value: i, label: `Item ${i + 1}` }));
        return (
            <AppLayout status={true}>
                <Card className="splash">
                    <div className="header">
                        <h5>Katal 3</h5>
                    </div>
                    {!!user && (
                        <div className="list">
                            <Menu>
                                {user.modules.map((module) => {
                                    if (module)
                                        return (
                                            <MenuItem
                                                icon={<img src={`${module.folder}/favicon.svg`} />}
                                                key={module.uid}
                                                text={module.name}
                                                label={module.description}
                                                href={`${module.folder}/`}
                                                className={module.uid}
                                            />
                                        );
                                })}
                                {user.isDev() && (
                                    <MenuItem
                                        icon={<img src={DriverSVG} />}
                                        text="Driver"
                                        label="Rapport driver"
                                        href="https://driver.katal.pro/"
                                        className="driver"
                                    />
                                )}
                            </Menu>
                        </div>
                    )}
                </Card>
                {/* <div className='splash bp3-dialog' style={!!user ? {} : { display: 'none' }}>
					<div className={Classes.DIALOG_HEADER}>
						<img src='./favicon.svg' className='ikon' width={20} height={20} />
						<H5>Katal</H5>
						<div className='spacer' />
					</div>
				</div> */}
            </AppLayout>
        );
    };
}

const mapState = (state: SplashState) => ({
    user: state.auth.user,
});

const mapDispatch = (dispatch) => ({
    logout: () => dispatch(Auth.logout()),
    module: (module?: string) => dispatch(Auth.module(module)),
});

const connector = connect(mapState, mapDispatch);

export default connector(Splash);
