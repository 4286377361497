import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, InputGroup, Intent, Menu, MenuItem, Popover, PopoverPosition, Tooltip } from "@blueprintjs/core";
import { mdiAlert, mdiAlertOctagram, mdiAutorenew, mdiCheck, mdiFileRefreshOutline, mdiMenuDown, mdiSortVariantLock, } from "@mdi/js";
import { LG } from "big-l";
import classNames from "classnames";
import { format } from "date-fns";
import { HTTP, HTTPClient } from "katal3-client";
import { Component } from "react";
import { ComponentUtils, IKon } from "../..";
const LGR = LG.ns("LogsTab");
const defaultLogsTabState = {
    poll: false,
    lock: true,
    fetching: false,
    sessions: [],
    ios: [],
    types: [],
    filter: { levels: [], search: "" },
    logs: [],
    filtered: [],
};
const POLL_INTERVAL = 5010; // ms.
class LogsTab extends Component {
    constructor() {
        super(...arguments);
        this.state = defaultLogsTabState;
        this.fetched = [];
    }
    componentDidMount() {
        LGR.debug("Tab mounted", this.state);
        // this.fetchLogsState();
        this.setState({ poll: true });
    }
    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            delete this.timeout;
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        var _a;
        const { propsDiff, stateDiff } = ComponentUtils.diff(this, nextProps, nextState);
        let shouldUpdate = true;
        if (stateDiff.fetching) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                delete this.timeout;
            }
            if (nextState.poll && !nextState.fetching)
                this.timeout = setTimeout(() => this.fetchLogs(), POLL_INTERVAL);
        }
        if (stateDiff.poll && nextState.poll) {
            this.fetchLogs();
        }
        if (stateDiff.logs || stateDiff.filter) {
            if (stateDiff.filter && nextState.filter.session !== this.state.filter.session) {
                this.fetchLogs((_a = nextState.filter.session) === null || _a === void 0 ? void 0 : _a._id, true);
            }
            const filtered = nextState.logs.filter((log) => this.matchFilter(log, nextState.filter));
            this.setState({ filtered });
            shouldUpdate = false;
        }
        return shouldUpdate;
    }
    componentDidUpdate(prevProps, prevState) {
        var _a;
        const { lock, logs } = this.state;
        if (lock) {
            LGR.debug("componentDidUpdate", prevState.logs == this.state.logs);
            if (prevState.filtered != this.state.filtered) {
                LGR.debug("Srcolllllllll !!!");
                (_a = this.list) === null || _a === void 0 ? void 0 : _a.scrollTo(0, this.list.scrollHeight);
            }
        }
    }
    matchFilter(log, filter) {
        var _a;
        if (filter.levels.length && filter.levels.indexOf(log.level) < 0)
            return false;
        if (filter.io && ((_a = log.io) === null || _a === void 0 ? void 0 : _a.type) !== filter.io)
            return false;
        if (filter.search.length > 2 && !log.message.toUpperCase().includes(filter.search.toUpperCase()))
            return false;
        return true;
    }
    // fetchLogsState() {
    //     this.setState({ fetching: true });
    //     HTTPClient.get<HTTP.Admin.Log.State>("/status/logs/state")
    //         .then((result) => {
    //             const {ios, sessions, types} = result.data;
    //             this.setState{sessions, ios: ios || []})
    //         })
    //         .catch((e) => null);
    // }
    fetchLogs(session, reset) {
        var _a;
        LGR.debug("fetchLogs", session, reset);
        const { filter } = this.state;
        this.setState({ fetching: true });
        let { ios, sessions, types } = this.state;
        if (reset) {
            this.fetched = [];
            delete this.lastLogDate;
            ios = [];
            types = [];
            sessions = [];
            this.setState({ logs: [], ios, types, sessions });
        }
        let logUrl = "/status/logs";
        const askDate = new Date();
        if (this.lastLogDate)
            logUrl += `/${this.lastLogDate}`;
        const fetchParams = {};
        if (session) {
            fetchParams.session = session;
        }
        else if (!reset) {
            fetchParams.session = (_a = filter.session) === null || _a === void 0 ? void 0 : _a._id;
        }
        HTTPClient.get(logUrl, { params: fetchParams })
            .then((result) => {
            var _a, _b;
            if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.logs.length) {
                this.fetched.push(...result.data.logs);
                this.lastLogDate = Math.max(...result.data.logs.map((log) => log.date.valueOf()));
                this.displayFetched();
            }
            if ((_b = result.data) === null || _b === void 0 ? void 0 : _b.state) {
                const { ios: resIOs, sessions: resSessions, types: resTypes } = result.data.state;
                const updatesState = {};
                if (resIOs === null || resIOs === void 0 ? void 0 : resIOs.length) {
                    updatesState.ios = Array.from(new Set([...resIOs, ...ios])).sort();
                }
                if (resSessions === null || resSessions === void 0 ? void 0 : resSessions.length) {
                    updatesState.sessions = [
                        ...resSessions.filter((ses) => ses && !sessions.find((s) => (s._id = ses._id))),
                        ...sessions,
                    ];
                }
                if (resTypes === null || resTypes === void 0 ? void 0 : resTypes.length) {
                    updatesState.types = Array.from(new Set([...resTypes, ...types])).sort();
                }
                this.setState(updatesState);
            }
        })
            .catch((e) => LGR.error(e.message))
            .finally(() => this.setState({ fetching: false }));
    }
    displayFetched() {
        this.setState({
            logs: this.state.logs
                .concat(this.fetched)
                .sort((l1, l2) => (l1.date > l2.date ? 1 : l1.date < l2.date ? -1 : 0)),
        });
        this.fetched = [];
    }
    render() {
        const { poll, lock, filter, filtered, ios, sessions } = this.state;
        // LGR.debug("Got", ios, sessions);
        return (_jsxs("div", Object.assign({ className: "logs" }, { children: [_jsxs("div", Object.assign({ className: "actions" }, { children: [_jsxs(Popover, Object.assign({ popoverClassName: "debug", minimal: true, position: PopoverPosition.BOTTOM_LEFT }, { children: [_jsx(Button, Object.assign({ rightIcon: _jsx(IKon, { icon: mdiMenuDown, size: 12 }, void 0) }, { children: filter.levels.length
                                        ? filter.levels.length == 1
                                            ? filter.levels[0]
                                            : `${filter.levels[0]}...`
                                        : "level" }), void 0), _jsxs(Menu, { children: [_jsx(MenuItem, { text: "[ all ]", onClick: () => this.setState({ filter: Object.assign(Object.assign({}, filter), { levels: [] }) }) }, void 0), Object.values(HTTP.Admin.Log.Level).map((lvl) => (_jsx(MenuItem, { text: lvl, icon: filter.levels.indexOf(lvl) >= 0 ? _jsx(IKon, { icon: mdiCheck, size: 14 }, void 0) : null, onClick: () => {
                                                let newLevels = [...filter.levels];
                                                const levelIndex = newLevels.indexOf(lvl);
                                                if (levelIndex >= 0) {
                                                    newLevels.splice(levelIndex, 1);
                                                }
                                                else {
                                                    newLevels.push(lvl);
                                                }
                                                this.setState({
                                                    filter: Object.assign(Object.assign({}, filter), { levels: newLevels }),
                                                });
                                            } }, lvl)))] }, void 0)] }), void 0), _jsxs(Popover, Object.assign({ popoverClassName: "debug", minimal: true, position: PopoverPosition.BOTTOM_LEFT }, { children: [_jsx(Button, Object.assign({ rightIcon: _jsx(IKon, { icon: mdiMenuDown, size: 12 }, void 0) }, { children: filter.io || "io" }), void 0), _jsxs(Menu, { children: [_jsx(MenuItem, { text: "[ all ]", onClick: () => this.setState({ filter: Object.assign(Object.assign({}, filter), { io: undefined }) }) }, void 0), ios.map((io) => (_jsx(MenuItem, { text: io, icon: filter.io == io ? _jsx(IKon, { icon: mdiCheck, size: 14 }, void 0) : null, onClick: () => this.setState({ filter: Object.assign(Object.assign({}, filter), { io }) }) }, `${io}`)))] }, void 0)] }), void 0), _jsxs(Popover, Object.assign({ minimal: true, popoverClassName: "debug", position: PopoverPosition.BOTTOM_LEFT }, { children: [_jsx(Button, Object.assign({ rightIcon: _jsx(IKon, { icon: mdiMenuDown, size: 12 }, void 0) }, { children: filter.session
                                        ? `${filter.session.type} ${format(filter.session.date, "dd/MM HH:mm")}`
                                        : "sessions" }), void 0), _jsxs(Menu, Object.assign({ className: "sessions" }, { children: [_jsx(MenuItem, { text: "[ all ]", onClick: () => this.setState({ filter: Object.assign(Object.assign({}, filter), { session: undefined }) }) }, void 0), sessions === null || sessions === void 0 ? void 0 : sessions.map((ses) => (_jsx(MenuItem, { text: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "type" }, { children: ses.type }), void 0), _jsx("span", Object.assign({ className: "date" }, { children: format(ses.date, "dd/MM HH:mm") }), void 0)] }, void 0), onClick: () => this.setState({ filter: Object.assign(Object.assign({}, filter), { session: ses }) }) }, ses._id)))] }), void 0)] }), void 0), _jsx(InputGroup, { value: filter.search, onChange: (txt) => this.setState({ filter: Object.assign(Object.assign({}, filter), { search: txt.currentTarget.value }) }) }, void 0), _jsx("div", { className: "spacer" }, void 0), _jsx(Button, Object.assign({ icon: _jsx(IKon, { icon: mdiFileRefreshOutline, size: 14 }, void 0), disabled: poll, onClick: () => this.fetchLogs() }, { children: "Refresh" }), void 0), _jsx(Tooltip, Object.assign({ openOnTargetFocus: false, usePortal: false, content: "Lock scroll to bottom", minimal: true, position: PopoverPosition.BOTTOM }, { children: _jsx(Button, { icon: _jsx(IKon, { icon: mdiSortVariantLock, size: 14 }, void 0), active: lock, onClick: () => this.setState({ lock: !lock }) }, void 0) }), void 0), _jsx(Tooltip, Object.assign({ usePortal: false, content: "Auto refresh", minimal: true, position: PopoverPosition.BOTTOM, openOnTargetFocus: false }, { children: _jsx(Button, { icon: _jsx(IKon, { icon: mdiAutorenew, size: 14 }, void 0), active: poll, intent: poll ? Intent.WARNING : undefined, onClick: () => this.setState({ poll: !poll }) }, void 0) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: "list", ref: (lst) => {
                        this.list = lst;
                    } }, { children: filtered.map((log, i) => (_jsxs("div", Object.assign({ className: classNames("line", log.level) }, { children: [_jsx("span", Object.assign({ className: "date" }, { children: format(log.date, "yyyy-MM-dd HH:mm:ss.SSS") }), void 0), _jsx("span", Object.assign({ className: "type", title: `${log.level}` }, { children: log.session.type }), void 0), !!log.io && _jsx("span", Object.assign({ className: "io" }, { children: log.io.type }), void 0), log.level === HTTP.Admin.Log.Level.ERROR ? (_jsx(IKon, { className: "level", icon: mdiAlertOctagram, size: 12 }, void 0)) : log.level === HTTP.Admin.Log.Level.WARN ? (_jsx(IKon, { className: "level", icon: mdiAlert, size: 12 }, void 0)) : null, _jsx("span", Object.assign({ className: "message" }, { children: `${log.message}` }), void 0)] }), `log-${i}`))) }), void 0)] }), void 0));
    }
}
export default LogsTab;
