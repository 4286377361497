export var AuthActionType;
(function (AuthActionType) {
    AuthActionType["SET_LOADING"] = "AUTH/SET_LOADING";
    AuthActionType["SET_USER"] = "AUTH/SET_USER";
    AuthActionType["SET_MODULE"] = "AUTH/SET_MODULE";
    AuthActionType["SET_ERROR"] = "AUTH/SET_ERROR";
})(AuthActionType || (AuthActionType = {}));
export const setUser = (user) => ({
    type: AuthActionType.SET_USER,
    user,
});
export const setLoading = (loading) => ({
    type: AuthActionType.SET_LOADING,
    loading,
});
export const setModule = (module) => ({
    type: AuthActionType.SET_MODULE,
    module,
});
export const setError = (error) => ({
    type: AuthActionType.SET_ERROR,
    error,
});
