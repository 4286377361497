"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringUtils = void 0;
var ObjectUtils_1 = require("./ObjectUtils");
var StringUtils;
(function (StringUtils) {
    var Case;
    (function (Case) {
        Case["CAMEL"] = "CASE/Camel";
        Case["SNAKE"] = "CASE/Snake";
        Case["KEBAB"] = "CASE/Kebab";
        Case["PASCAL"] = "CASE/Pascal";
    })(Case = StringUtils.Case || (StringUtils.Case = {}));
    function capitalize(str, lowerize, separator) {
        if (lowerize === void 0) { lowerize = true; }
        if (separator === void 0) { separator = " "; }
        return str
            .split(separator)
            .map(function (s) { return s.charAt(0).toUpperCase() + (lowerize ? s.slice(1).toLowerCase() : s.slice(1)); })
            .join(separator);
    }
    StringUtils.capitalize = capitalize;
    function toFilename(str) {
        var following = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            following[_i - 1] = arguments[_i];
        }
        var strings = __spreadArrays([str], following).filter(function (s) { return s && s.length; })
            .map(function (s) {
            return s
                .trim()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/([^\w^ ]*)/g, "")
                .replace(/([ ]+)/g, "-");
        });
        return strings.join("-");
    }
    StringUtils.toFilename = toFilename;
    function normalize(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    StringUtils.normalize = normalize;
    function guessCase(str) {
        if (str.indexOf("-") > 0)
            return Case.KEBAB;
        if (str.indexOf("_") > 0)
            return Case.SNAKE;
        var lowerFirst = /^([0-9]*)[a-z]/;
        return lowerFirst.test(str) ? Case.CAMEL : Case.PASCAL;
    }
    StringUtils.guessCase = guessCase;
    function splitFromCase(str, sourceCase) {
        switch (sourceCase) {
            case Case.KEBAB:
                return str.split("-");
            case Case.SNAKE:
                return str.split("_");
            case Case.CAMEL:
                return str.match(/(([A-Z]?)[a-z]+|[0-9]+)/g) || [];
            case Case.PASCAL:
                return str.match(/([A-Z][a-z]+|[0-9]+)/g) || [];
        }
    }
    StringUtils.splitFromCase = splitFromCase;
    function joinToCase(elements, targetCase) {
        var transform, join = "";
        switch (targetCase) {
            case Case.CAMEL:
                transform = function (element, index) { return (index ? capitalize(element) : element.toLowerCase()); };
                break;
            case Case.SNAKE:
                transform = function (element) { return element.toLowerCase(); };
                join = "_";
                break;
            case Case.KEBAB:
                transform = function (element) { return element.toLowerCase(); };
                join = "-";
                break;
            case Case.PASCAL:
                transform = function (element) { return capitalize(element); };
                break;
        }
        return transform ? elements.map(transform).join(join) : elements.join(join);
    }
    StringUtils.joinToCase = joinToCase;
    function changeCase(str, toCase, fromCase) {
        fromCase = fromCase || guessCase(str);
        return joinToCase(splitFromCase(str, fromCase), toCase);
    }
    StringUtils.changeCase = changeCase;
    function limit(text, size, ellipsis) {
        if (text.length > size) {
            var ell = ellipsis === undefined || ellipsis;
            text = text.substr(0, ell ? size - 1 : size);
            if (ell)
                text += String.fromCharCode(8230);
        }
        return text;
    }
    StringUtils.limit = limit;
    // Micro template engine.
    // {{var}} get replaced if found in variables parameter
    // (works with value path in object or array: 'foo.bar', 'arr.1.name', ...)
    //
    // {{var||default}} get replaced with default value if not found
    // {{var||%%default}} same as above, force numeric value if json
    function template(input, variables, json, options) {
        var regexpBase = "(['\"']?){{([^\\s(?:}})]+?)(?:(?:(\\|\\||\\?\\?)(.*))?)}}(\\1)";
        var rgxp = new RegExp(regexpBase, "g");
        var cached = {};
        var varvalue = function (field) {
            var value = cached[field];
            if (value !== undefined)
                return value;
            if (value === undefined) {
                var currentValue = variables;
                var path = field.split(".");
                while (path.length && typeof currentValue == "object") {
                    currentValue = currentValue[path.shift()];
                }
                value = cached[field] = currentValue;
            }
            if (value === undefined)
                console.warn(field, "not found");
            return value;
        };
        var replaced = input.replace(rgxp, function (found) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            var quote = args[0], key = args[1], op = args[2], def = args[3];
            var value = varvalue(key);
            if (def !== undefined) {
                console.log("Default value", key, op + "=>", def);
                switch (true) {
                    case op === "??" && value !== undefined && value !== false:
                    case op === "||" && value === undefined:
                        if (json && def.startsWith("%%")) {
                            value = parseFloat(def.substr(2));
                        }
                        else {
                            if (json && def.match(new RegExp("^" + regexpBase + "$"))) {
                                var templated = template(def, variables, json, options);
                                try {
                                    value = JSON.parse(templated);
                                }
                                catch (e) {
                                    value = templated;
                                }
                            }
                            else {
                                value = template(def, variables, json, options);
                            }
                        }
                        break;
                    case op === "??" && (value === undefined || value === false):
                        value = json ? null : "";
                        break;
                }
            }
            if (value === undefined)
                return found;
            if (json) {
                return !!quote || typeof value === "object" ? ObjectUtils_1.ObjectUtils.stringify(value, options) : value;
            }
            else {
                return quote + ("" + value) + quote;
            }
        });
        return replaced;
    }
    StringUtils.template = template;
    // static camelToSnake(str: string): string {
    // 	return this.joinToCase(this.splitFromCase(str, Case.CAMEL), Case.SNAKE);
    // 	// let matches = str.match(/(([A-Z]?)[a-z]+)/g);
    // 	// return matches ? matches.map(s => s.toLowerCase()).join('_') : str;
    // }
    // static snakeToCamel(str: string): string {
    // 	return str
    // 		.split('_')
    // 		.map((s, i) => (i > 0 ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : s))
    // 		.join('');
    // }
})(StringUtils = exports.StringUtils || (exports.StringUtils = {}));
