import { WebWorker } from './WebWorker';
export class ZipClient extends WebWorker.Client {
    constructor() {
        super('/workers/zip.js');
    }
    compress(contentOrFiles, fileName) {
        return this.call('compress', contentOrFiles, fileName);
    }
    extract(zip, file, type) {
        return this.call('extract', zip, file, type);
    }
}
