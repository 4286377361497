import { AppActionType, setLocalConfig as infoAction, setReady as readyAction } from "./actions";
import { appReducer } from "./reducer";
import { execute as executeAction, getLocalConfig as getLocalConfigAction, getServerConfig as getServerConfigAction, getStatus as getStatusAction, startLoading as loadAction, startWorking, } from "./thunks";
export var App;
(function (App) {
    App.reducer = appReducer;
    App.ready = readyAction;
    App.infos = infoAction;
    App.load = loadAction;
    App.work = startWorking;
    App.exec = executeAction;
    App.serverConfig = getServerConfigAction;
    App.localConfig = getLocalConfigAction;
    App.getStatus = getStatusAction;
    App.ActionType = AppActionType;
})(App || (App = {}));
