import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { mdiAccountCowboyHat } from "@mdi/js";
import { LG } from "big-l";
import React from "react";
import HTTPTab from "./debug/HTTPTab";
import LGTab from "./debug/LGTab";
import LogsTab from "./debug/LogsTab";
import PlaygroundTab from "./debug/PlaygroundTab";
import { KatalDialog } from "./KatalDialog";
// highlightjs.registerLanguage('json', jsonLang);
// highlightjs.registerLanguage('html', htmlLang);
const LGR = LG.ns("DebugWindow");
// const LOGGER_OPTIONS: { [key: string]: { label: string; icon: string } } = {
// 	date: {
// 		label: 'Date & time',
// 		icon: mdiClockOutline,
// 	},
// 	time: {
// 		label: 'Elapsed time',
// 		icon: mdiTimerOutline,
// 	},
// 	stack: {
// 		label: 'Call stack',
// 		icon: mdiFunction,
// 	},
// };
// const DEFAULT_LOGGER_NAMESPACE = '__default';
// const MAX_ARRAY_PREVIEW = 2;
const defaultDebugWindowState = {
    opened: true,
    // httpRunning: false,
    // httpPath: '',
    // loggers: [],
};
export class DebugWindow extends React.Component {
    constructor() {
        super(...arguments);
        this.state = defaultDebugWindowState;
    }
    render() {
        const { opened } = this.state;
        return (_jsx(KatalDialog, Object.assign({ className: "debug-window", isOpen: opened, title: "Debug and test", svgIcon: mdiAccountCowboyHat, onClose: () => this.setState({ opened: false }), onClosed: this.props.onClose, portalClassName: "top-portal" }, { children: _jsx("div", Object.assign({ className: Classes.DIALOG_BODY }, { children: _jsxs(Tabs, Object.assign({ renderActiveTabPanelOnly: true }, { children: [_jsx(Tab, { id: "http", title: "HTTP", panel: _jsx(HTTPTab, {}, void 0) }, void 0), _jsx(Tab, { id: "loggers", title: _jsxs(_Fragment, { children: ["LG ", _jsx("em", { children: "by big-l" }, void 0)] }, void 0), panel: _jsx(LGTab, {}, void 0) }, void 0), _jsx(Tab, { id: "logs", title: "logs", panel: _jsx(LogsTab, {}, void 0) }, void 0), _jsx(Tab, { id: "playground", title: "play", panel: _jsx(PlaygroundTab, {}, void 0) }, void 0)] }), void 0) }), void 0) }), void 0));
    }
}
