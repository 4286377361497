import { LG } from 'big-l';
import { AuthActionType } from './actions';
import { AuthError, AuthErrorType, } from './types';
const LGR = LG.ns('authReducer');
const defaultUserState = {
    ready: false,
    loading: false,
};
const ERRORS = {
    not_allowed: new AuthError(AuthErrorType.NOT_ALLOWED, 'Opération non autorisée'),
    wrong_credentials: new AuthError(AuthErrorType.WRONG_CREDENTIALS, 'Utilisateur inconnu'),
};
export const authReducer = (state = defaultUserState, action) => {
    let newState = state;
    const { user: stateUser, module: stateModule } = state;
    switch (action.type) {
        case AuthActionType.SET_USER:
            let pAction = action;
            let { user } = pAction;
            let userError;
            if (user) {
                user.module = stateModule;
            }
            newState = Object.assign(Object.assign({}, state), { user, error: userError, ready: true });
            break;
        case AuthActionType.SET_LOADING:
            let ldAction = action;
            let { loading } = ldAction;
            newState = Object.assign(Object.assign({}, state), { loading: ldAction.loading });
            if (loading)
                delete newState.error;
            break;
        case AuthActionType.SET_MODULE:
            let mdAction = action;
            let { module } = mdAction;
            let moduleUser = stateUser, moduleError;
            if (stateUser) {
                stateUser.module = module;
            }
            newState = Object.assign(Object.assign({}, state), { module, user: moduleUser, error: moduleError });
            break;
        case AuthActionType.SET_ERROR:
            let errAction = action;
            let { error } = errAction;
            if (error) {
                newState = Object.assign(Object.assign({}, state), { error: ERRORS[error] });
            }
            else {
                newState = Object.assign(Object.assign({}, state), { error: undefined });
            }
            break;
    }
    return newState;
};
