"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoneyUtils = void 0;
var MoneyUtils;
(function (MoneyUtils) {
    function add(price, percent) {
        return price * (1 + percent);
    }
    MoneyUtils.add = add;
    function sub(price, percent) {
        return price / (1 + percent);
    }
    MoneyUtils.sub = sub;
    function margin(buy, sellOrTaxOptions, taxOptions) {
        if (sellOrTaxOptions === void 0) { sellOrTaxOptions = {}; }
        if (taxOptions === void 0) { taxOptions = {}; }
        if (typeof sellOrTaxOptions === "number") {
            var income = (taxOptions.percent ? sub(sellOrTaxOptions, taxOptions.percent) : sellOrTaxOptions) -
                (taxOptions.offset || 0);
            return 1 - buy / income;
        }
        else {
            var newPrice = buy / (1 - (sellOrTaxOptions.percent || 0)) + (taxOptions.offset || 0);
            return newPrice;
        }
    }
    MoneyUtils.margin = margin;
    // export function applyMargin(price: number, percent: number, decimals = 0): number {
    // 	const newPrice = price / (1 - percent);
    // 	return NumberUtils.round(newPrice, decimals);
    // }
    function difference(price, compare) {
        return (price - compare) / (compare > 0 ? compare : -compare);
    }
    MoneyUtils.difference = difference;
})(MoneyUtils = exports.MoneyUtils || (exports.MoneyUtils = {}));
