"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseHTTP = void 0;
var Admin_1 = require("./http/Admin");
var Echo_1 = require("./http/Echo");
var Katal_1 = require("./http/Katal");
var Taro_1 = require("./http/Taro");
var BaseHTTP;
(function (BaseHTTP) {
    var Operation;
    (function (Operation) {
        Operation.FAIL = function (error) { return ({
            success: false,
            error: error || 'Undocumented Fail',
        }); };
        Operation.SUCCESS = function (data) { return (__assign(__assign({}, data), { success: true })); };
        Operation.PAGINATION = function (id) { return ({ success: true, id: id }); };
    })(Operation = BaseHTTP.Operation || (BaseHTTP.Operation = {}));
    BaseHTTP.Katal = Katal_1.Katal;
    BaseHTTP.Admin = Admin_1.Admin;
    BaseHTTP.Echo = Echo_1.Echo;
    BaseHTTP.Taro = Taro_1.Taro;
})(BaseHTTP = exports.BaseHTTP || (exports.BaseHTTP = {}));
