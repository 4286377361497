"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogLevel = void 0;
// Types
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["EMERGENCY"] = 0] = "EMERGENCY";
    LogLevel[LogLevel["ALERT"] = 1] = "ALERT";
    LogLevel[LogLevel["CRITICAL"] = 2] = "CRITICAL";
    LogLevel[LogLevel["ERROR"] = 3] = "ERROR";
    LogLevel[LogLevel["WARNING"] = 4] = "WARNING";
    LogLevel[LogLevel["NOTICE"] = 5] = "NOTICE";
    LogLevel[LogLevel["INFO"] = 6] = "INFO";
    LogLevel[LogLevel["VERBOSE"] = 7] = "VERBOSE";
    LogLevel[LogLevel["DEBUG"] = 8] = "DEBUG";
    LogLevel[LogLevel["WHO_CARES"] = 9] = "WHO_CARES";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
