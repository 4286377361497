import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FocusStyleManager } from "@blueprintjs/core";
import { LG } from "big-l";
import { App, Auth, Browser } from "katal3-client";
import React from "react";
import { connect, Provider } from "react-redux";
import { ComponentUtils } from ".";
import "../sass/katal-app.sass";
import AuthDialog from "./components/AuthDialog";
const LGR = LG.ns("KatalApp");
function redirectMobile() {
    if (document.location.hostname === "localhost")
        return false;
    const isMobile = Browser.isMobile(), mobileURL = `${document.location.protocol}//mobile.${document.location.host.replace(/(?:(?:www\.)?)(.+)/, "$1")}`;
    LGR.debug("Mobile ?", isMobile, "=>", mobileURL);
    if (isMobile) {
        document.location.replace(mobileURL);
        return true;
    }
}
class KatalApp extends React.Component {
    componentDidMount() {
        FocusStyleManager.onlyShowFocusOnTabs();
        this.props.getLocalConfig();
    }
    shouldComponentUpdate(props) {
        const { propsDiff, stateDiff } = ComponentUtils.diff(this, props);
        LGR.debug(propsDiff, stateDiff);
        if (propsDiff.authLoading)
            LGR.debug("authLoading", props.authLoading);
        if (propsDiff.localConfig && props.localConfig) {
            if (props.module)
                document.title = `Katal | ${props.module}`;
            this.props.setAuthModule(props.module);
            this.props.getServerConfig(props.module);
        }
        if ((propsDiff.authReady && props.authReady) || propsDiff.serverConfig) {
            return true;
        }
        if (!!props.user && !props.ready) {
            this.props.setReady();
        }
        if (propsDiff.ready && props.ready) {
            return true;
        }
        return false;
    }
    render() {
        const { ready, module, authReady, serverConfig, auth, user, hideOnDisconnect } = this.props;
        LGR.debug(this.props);
        return (_jsxs(Provider, Object.assign({ store: this.props.store }, { children: [authReady && serverConfig ? auth || _jsx(AuthDialog, { module: module }, void 0) : null, ready && (user || !hideOnDisconnect) ? this.props.children : null] }), void 0));
    }
}
KatalApp.mobile = redirectMobile();
const mapState = (state) => ({
    ready: state.app.ready,
    user: state.auth.user,
    localConfig: state.app.localConfig,
    serverConfig: state.app.serverConfig,
    authLoading: state.auth.loading,
    authModule: state.auth.module,
    authReady: state.auth.ready,
});
const mapDispatch = (dispatch) => ({
    setAuthModule: (mod) => dispatch(Auth.module(mod)),
    setReady: () => dispatch(App.ready()),
    getLocalConfig: () => dispatch(App.localConfig()),
    getServerConfig: (module) => dispatch(App.serverConfig(module)),
});
const connector = connect(mapState, mapDispatch);
export default connector(KatalApp);
