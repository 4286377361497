var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@mdi/react';
import { Component } from 'react';
export default class IKon extends Component {
    render() {
        let _a = this.props, { icon, size, className } = _a, props = __rest(_a, ["icon", "size", "className"]);
        return _jsx(Icon, Object.assign({}, props, { className: `ikon ${className || ''}`, path: icon, size: size === undefined ? size : `${size}px` }), void 0);
    }
}
