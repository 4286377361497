var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Classes, Dialog } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import "../../sass/katal-dialog.sass";
import IKon from "./IKon";
export class KatalDialog extends React.Component {
    render() {
        const _a = this.props, { title, svgIcon, intent, children, className, corner } = _a, props = __rest(_a, ["title", "svgIcon", "intent", "children", "className", "corner"]);
        return (_jsxs(Dialog, Object.assign({}, props, { className: classNames("katal-dialog", intent ? "intent-" + intent : "", className) }, { children: [title || corner || svgIcon ? (_jsxs("div", Object.assign({ className: Classes.DIALOG_HEADER }, { children: [svgIcon ? _jsx(IKon, { icon: svgIcon, className: "dialog-icon", size: 16 }, void 0) : null, title, corner ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "spacer" }, void 0), corner] }, void 0)) : undefined] }), void 0)) : null, children] }), void 0));
    }
}
