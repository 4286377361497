import { authMiddleware } from './middleware';
import { authReducer } from './reducer';
import { load as loadAction, login as loginAction, logout as logoutAction, module as moduleAction } from './thunks';
import { AuthError, AuthErrorType } from './types';
export var Auth;
(function (Auth) {
    Auth.reducer = authReducer;
    Auth.middleware = authMiddleware;
    Auth.login = loginAction;
    Auth.logout = logoutAction;
    Auth.load = loadAction;
    Auth.module = moduleAction;
    Auth.Error = AuthError;
    Auth.ErrorType = AuthErrorType;
})(Auth || (Auth = {}));
