"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectUtils = void 0;
var date_fns_1 = require("date-fns");
var StringUtils_1 = require("./StringUtils");
var UID_KEY = "__$biglUID$__";
var UID_INDEX = 0;
function jsonReplacer(options) {
    return function (k, v) {
        var value = v;
        if (options.exclude) {
            if (typeof options.exclude === "function" && options.exclude(k, this[k], this))
                return undefined;
            if (Array.isArray(options.exclude) && options.exclude.indexOf(k) >= 0)
                return undefined;
        }
        if ((options.dateFormat || options.parseDates) && this[k] instanceof Date)
            value = options.dateFormat ? date_fns_1.format(this[k], options.dateFormat) : date_fns_1.formatISO(this[k]);
        if (typeof options.replacer === "function")
            value = options.replacer.call(this, k, value);
        return value;
    };
}
function parseJSONDate(dateString) {
    if (typeof dateString === "string") {
        var parts = dateString.match(/^(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2})(?:\.(\d{0,7}))?(?:Z|(.)(\d{2}):?(\d{2})?)?$/);
        if (parts) {
            // Group 8 matches the sign
            return new Date(Date.UTC(+parts[1], +parts[2] - 1, +parts[3], +parts[4] - (+parts[9] || 0) * (parts[8] == "-" ? -1 : 1), +parts[5] - (+parts[10] || 0) * (parts[8] == "-" ? -1 : 1), +parts[6], +((parts[7] || "0") + "00").substring(0, 3)));
        }
        return new Date(NaN);
    }
    return date_fns_1.toDate(dateString);
}
function jsonReviver(options) {
    return function (k, v) {
        if (options.parseDates || options.dateFormat || (options.dateKeys && options.dateKeys.indexOf(k) >= 0)) {
            var dt = void 0;
            if (typeof v === "string") {
                dt = options.dateFormat
                    ? (date_fns_1.isMatch(v, options.dateFormat) || undefined) && date_fns_1.parse(v, options.dateFormat, new Date())
                    : parseJSONDate(v);
            }
            if (date_fns_1.isValid(dt))
                return dt;
        }
        if (options.reviver)
            return options.reviver.call(this, k, v);
        return v;
    };
}
var ObjectUtils;
(function (ObjectUtils) {
    function uid(o) {
        if (typeof o == "object") {
            if (!o[UID_KEY]) {
                var uid_1 = "" + ++UID_INDEX + new Date().getTime();
                Object.defineProperty(o, UID_KEY, {
                    value: uid_1,
                    enumerable: false,
                });
                return uid_1;
            }
            else {
                return o[UID_KEY];
            }
        }
        return null;
    }
    ObjectUtils.uid = uid;
    function clearUid(o) {
        if (o[UID_KEY])
            delete o[UID_KEY];
    }
    ObjectUtils.clearUid = clearUid;
    function reduce(o, fct, init) {
        var reduced = init;
        for (var key in o) {
            reduced = fct(reduced, key, o[key], o);
        }
        return reduced;
    }
    ObjectUtils.reduce = reduce;
    function mapKeys(o, fct, recursive) {
        if (recursive === void 0) { recursive = false; }
        if (typeof o != "object")
            return o;
        if (Array.isArray(o))
            return o.map(function (ae) { return mapKeys(ae, fct, recursive); });
        var r = {};
        for (var k in o) {
            var v = o[k];
            r[fct(k, v)] =
                recursive && v && (v.constructor == Object || v.constructor == Array) ? mapKeys(o[k], fct, true) : o[k];
        }
        return r;
    }
    ObjectUtils.mapKeys = mapKeys;
    function map(o, fct) {
        var r = {};
        for (var k in o) {
            r[k] = fct(o[k], k);
        }
        return r;
    }
    ObjectUtils.map = map;
    function toRegistry(a, key) {
        return a.reduce(function (red, v) {
            var _a;
            return (__assign(__assign({}, red), (_a = {}, _a[typeof key == "string" ? key : key(v)] = v, _a)));
        }, {});
    }
    ObjectUtils.toRegistry = toRegistry;
    function isEmpty(o) {
        for (var k in o)
            if (o[k] !== undefined)
                return false;
        return true;
    }
    ObjectUtils.isEmpty = isEmpty;
    // export function pick<T, K extends keyof T>(source: T, keys: K[]): { [P in K]: T[P] };
    function pick(source, fields) {
        var newObject = {};
        assign(newObject, source, fields);
        return newObject;
    }
    ObjectUtils.pick = pick;
    function omit(source, fields) {
        var ret = __assign({}, source);
        for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
            var k = fields_1[_i];
            delete ret[k];
        }
        return ret;
    }
    ObjectUtils.omit = omit;
    function exclude(source, fields) {
        var actualKeys = Object.keys(source);
        var keepKeys = [];
        for (var _i = 0, actualKeys_1 = actualKeys; _i < actualKeys_1.length; _i++) {
            var k = actualKeys_1[_i];
            if (fields.indexOf(k) < 0)
                keepKeys.push(k);
        }
        return pick(source, keepKeys);
    }
    ObjectUtils.exclude = exclude;
    function assign(target, source, fields) {
        fields = fields || Object.keys(source);
        var valueObject = {};
        var assignProperty = function (tgt, key, value) {
            if (value !== undefined)
                tgt[key] = value;
        };
        for (var _i = 0, fields_2 = fields; _i < fields_2.length; _i++) {
            var k = fields_2[_i];
            if (!k)
                continue;
            if (typeof k == "string") {
                assignProperty(valueObject, k, source[k]);
            }
            else {
                var ck = k;
                for (var fk in ck) {
                    var src = ck[fk];
                    if (typeof src == "string") {
                        var sourceKey = ck[fk];
                        assignProperty(valueObject, fk, source[sourceKey]);
                    }
                    else if (typeof src == "function") {
                        assignProperty(valueObject, fk, src(source));
                    }
                }
            }
        }
        Object.assign(target, valueObject);
    }
    ObjectUtils.assign = assign;
    function isDifferent(o1, o2, fields, strict) {
        if (strict === void 0) { strict = true; }
        for (var _i = 0, fields_3 = fields; _i < fields_3.length; _i++) {
            var k = fields_3[_i];
            if (strict && o1[k] !== o2[k])
                return true;
            if (!strict && o1[k] != o2[k])
                return true;
        }
        return false;
    }
    ObjectUtils.isDifferent = isDifferent;
    function isDefined(o) {
        return o !== null && o !== undefined;
    }
    ObjectUtils.isDefined = isDefined;
    function diff(obj, compare) {
        var dif = {};
        for (var k in obj) {
            if (obj[k] !== compare[k])
                dif[k] = obj[k];
        }
        return dif;
    }
    ObjectUtils.diff = diff;
    function changed(obj, compare) {
        var changed = {};
        for (var k in obj) {
            if (obj[k] !== compare[k])
                changed[k] = true;
        }
        for (var k in compare) {
            if (!changed[k] && compare[k] !== obj[k])
                changed[k] = true;
        }
        return changed;
    }
    ObjectUtils.changed = changed;
    function registry(arr, field) {
        var reg = {};
        arr.forEach(function (o) {
            reg["" + o[field]] = o;
        });
        return reg;
    }
    ObjectUtils.registry = registry;
    function stringify(value, options, space) {
        if (options === null || options === void 0 ? void 0 : options.keyTransform) {
            value = ObjectUtils.mapKeys(value, options.keyTransform, true);
        }
        return JSON.stringify(value, options ? jsonReplacer(options) : undefined, space);
    }
    ObjectUtils.stringify = stringify;
    function parse(text, options) {
        if (!text)
            return null;
        var obj;
        if (options === null || options === void 0 ? void 0 : options.variables) {
            text = StringUtils_1.StringUtils.template(text, options.variables, true);
        }
        if (options === null || options === void 0 ? void 0 : options.reviver) {
            obj = JSON.parse(text, options.reviver);
        }
        else {
            obj = JSON.parse(text, options ? jsonReviver(options) : undefined);
        }
        if (options === null || options === void 0 ? void 0 : options.keyTransform)
            obj = ObjectUtils.mapKeys(obj, options.keyTransform, true);
        return obj;
    }
    ObjectUtils.parse = parse;
    function eq(o1, o2) {
        if (o1 === null || o1 === undefined || o2 === null || o2 === undefined) {
            return o1 === o2;
        }
        // after this just checking type of one would be enough
        if (o1.constructor !== o2.constructor) {
            return false;
        }
        // if they are functions, they should exactly refer to same one (because of closures)
        if (o1 instanceof Function) {
            return o1 === o2;
        }
        // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
        if (o1 instanceof RegExp) {
            return o1 === o2;
        }
        if (o1 === o2 || o1.valueOf() === o2.valueOf()) {
            return true;
        }
        if (Array.isArray(o1) && o1.length !== o2.length) {
            return false;
        }
        // if they are dates, they must had equal valueOf
        if (o1 instanceof Date) {
            return false;
        }
        // if they are strictly equal, they both need to be object at least
        if (!(o1 instanceof Object)) {
            return false;
        }
        if (!(o1 instanceof Object)) {
            return false;
        }
        // recursive object equality check
        var p = Object.keys(o1);
        return (Object.keys(o2).every(function (i) {
            return p.indexOf(i) !== -1;
        }) &&
            p.every(function (i) {
                return eq(o1[i], o2[i]);
            }));
    }
    ObjectUtils.eq = eq;
    function copy(o) {
        if (null == o || "object" !== typeof o)
            return o;
        var copied;
        if (o instanceof Date) {
            var dt = new Date();
            dt.setTime(o.getTime());
            copied = dt;
            return copied;
        }
        if (Array.isArray(o)) {
            copied = o.map(copy);
            return copied;
        }
        if (o instanceof Object) {
            copied = {};
            for (var _i = 0, _a = Object.entries(o); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (o.hasOwnProperty(key))
                    copied[key] = copy(value);
            }
            return copied;
        }
        throw new Error("Object can't be copied");
    }
    ObjectUtils.copy = copy;
})(ObjectUtils = exports.ObjectUtils || (exports.ObjectUtils = {}));
