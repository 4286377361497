import data from "./nomenclatures.json";
const registry = {};
export class Nomenclature {
    constructor(code, name) {
        this.code = code;
        this.name = name;
        if (!registry[code]) {
            registry[code] = this;
        }
        else {
            throw new Error(`Nomenclature code ${code} already registered`);
        }
        let curLevel = "";
        let levels = `${code}`.split("");
        let char;
        while (!!(char = levels.shift())) {
            curLevel += char;
            let target = registry[parseInt(curLevel, 10)];
            switch (curLevel.length) {
                case 1:
                    this.range = target;
                    break;
                case 2:
                    this.subRange = target;
                    break;
                case 3:
                    this.family = target;
                    break;
                case 4:
                    this.subFamily = target;
                    break;
                case 5:
                    this.module = target;
                    break;
            }
        }
        if (this.range && !this.subRange) {
            Nomenclature._ranges.push(this);
        }
    }
    static registerNomenclature(o) {
        Nomenclature.getInstance(`${o.code}`, o.name);
        if (o.children) {
            for (let c of o.children) {
                this.registerNomenclature(c);
            }
        }
    }
    static registerNomenclatureList(lst) {
        Nomenclature._ranges = [];
        for (let tn of lst)
            this.registerNomenclature(tn);
        this._ranges.sort((n1, n2) => (n1.code > n2.code ? 1 : -1));
    }
    static getInstance(code, name) {
        // if (isNaN(code)) LG.once("nan-nom", code, "not numeric");
        return registry[code] || (name ? new Nomenclature(code, name) : undefined);
    }
    static get ranges() {
        return [...this._ranges];
    }
}
Nomenclature.init = Nomenclature.registerNomenclatureList(data);
