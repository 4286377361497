import ReactDOM from 'react-dom';
import { KatalApp } from '../../_react/lib';
import Splash from './Splash';
import store from './state/store';

console.log('Start splash');
console.log(ReactDOM);

ReactDOM.render(
	<KatalApp store={store} hideOnDisconnect={true}>
		<Splash />
	</KatalApp>,
	document.getElementById('root')
);
