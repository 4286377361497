"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
var date_fns_1 = require("date-fns");
var EnvUtils_1 = require("../utils/EnvUtils");
var types_1 = require("./types");
var styles_1 = require("./styles");
var const_1 = require("./const");
var inBrowser = EnvUtils_1.EnvUtils.isBrowser();
var inNode = EnvUtils_1.EnvUtils.isNode();
var stacktrace;
try {
    stacktrace = require(/* webpackIgnore: true */ "stacktrace-js");
}
catch (_b) { }
var chalk;
if (inNode) {
    try {
        chalk = require(/* webpackIgnore: true */ "chalk");
    }
    catch (e) { }
}
var utilInspect;
if (inNode) {
    try {
        utilInspect = (_a = require(/* webpackIgnore: true */ "util")) === null || _a === void 0 ? void 0 : _a.inspect;
    }
    catch (e) { }
}
var Logger;
(function (Logger) {
    Logger.Level = types_1.LogLevel;
    var systemGlobal = inBrowser ? window : inNode ? global : {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    var omni = (systemGlobal.__big_l || (systemGlobal.__big_l = {
        registry: {},
    }));
    var LoggerLimit = /** @class */ (function () {
        function LoggerLimit(instance, limit) {
            var _this = this;
            this.count = 0;
            this.emerg = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.emerg], args));
            };
            this.alert = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.alert], args));
            };
            this.crit = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.crit], args));
            };
            this.error = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.error], args));
            };
            this.warn = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.warn], args));
            };
            this.notice = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.notice], args));
            };
            this.info = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.info], args));
            };
            this.verb = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.verb], args));
            };
            this.debug = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.debug], args));
            };
            this.wth = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.limitedCall.apply(_this, __spreadArrays([_this.instance.wth], args));
            };
            this.instance = instance;
            this.limit = limit;
        }
        LoggerLimit.prototype.limitedCall = function (cb) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            if (this.count < this.limit) {
                this.count++;
                cb.apply(void 0, args);
            }
            else if (this.count == this.limit) {
                this.count++;
                cb("Limited to", this.limit);
            }
        };
        LoggerLimit.prototype.reset = function () {
            this.count = 0;
        };
        return LoggerLimit;
    }());
    var LoggerInstance = /** @class */ (function () {
        function LoggerInstance(nameSpace, options) {
            var _this = this;
            if (options === void 0) { options = {}; }
            this._onces = {};
            this._limits = {};
            this.lastLogTime = 0;
            /* ------------- */
            this.ns = Logger.ns;
            this.emerg = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.EMERGENCY], args));
            };
            this.alert = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.ALERT], args));
            };
            this.crit = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.CRITICAL], args));
            };
            this.error = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.ERROR], args));
            };
            this.warn = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.WARNING], args));
            };
            this.notice = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.NOTICE], args));
            };
            this.info = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.INFO], args));
            };
            this.verb = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.VERBOSE], args));
            };
            this.debug = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.DEBUG], args));
            };
            this.wth = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return _this.log.apply(_this, __spreadArrays([types_1.LogLevel.WHO_CARES], args));
            };
            this._namespace = nameSpace;
            this._options = options;
            this._computedOptions = const_1.DEFAULT_LOGGER_OPTIONS;
            this.computeOptions();
        }
        Object.defineProperty(LoggerInstance.prototype, "namespace", {
            get: function () {
                return this._namespace;
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "exclusive", {
            get: function () {
                return omni.exclusive === this;
            },
            set: function (b) {
                Logger.exclusive(b ? this : undefined);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "enabled", {
            get: function () {
                return this.getOption("enabled");
            },
            /* -- OPTIONS -- */
            set: function (b) {
                this.setOption("enabled", b);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "stack", {
            get: function () {
                return this.getOption("stack");
            },
            set: function (b) {
                this.setOption("stack", b);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "time", {
            get: function () {
                return this.getOption("time");
            },
            set: function (b) {
                this.setOption("time", b);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "date", {
            get: function () {
                return this.getOption("date");
            },
            set: function (b) {
                this.setOption("date", b);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "level", {
            get: function () {
                return this.getOption("level");
            },
            set: function (level) {
                this.setOption("level", level);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "pad", {
            get: function () {
                return this.getOption("pad");
            },
            set: function (b) {
                this.setOption("pad", b);
            },
            enumerable: false,
            configurable: true
        });
        LoggerInstance.prototype.setOption = function (name, value) {
            if (this._computedOptions[name] !== value) {
                // console.log(`Set ${this.namespace || ""} option`, name, "to", value);
                this._options[name] = value;
                this.computeOptions();
                // console.log(name, "is now", this._computedOptions[name]);
            }
        };
        LoggerInstance.prototype.getOption = function (name) {
            return this._computedOptions[name];
        };
        LoggerInstance.prototype.computeOptions = function () {
            var options = __assign({}, rootInstance._computedOptions);
            for (var _i = 0, _a = Object.keys(options); _i < _a.length; _i++) {
                var opt = _a[_i];
                switch (opt) {
                    case "enabled":
                        options[opt] && (options[opt] = this._options.enabled === undefined || !!this._options[opt]);
                        break;
                    case "stack":
                        options[opt] || (options[opt] = !!this._options[opt]);
                        break;
                    case "level":
                        options.level = Math.min(options.level || types_1.LogLevel.WHO_CARES, rootInstance.level || types_1.LogLevel.WHO_CARES);
                        break;
                    case "date":
                    case "time":
                    case "pad":
                        if (this._options[opt] !== undefined)
                            options[opt] = this._options[opt];
                        break;
                }
            }
            this._computedOptions = options;
        };
        LoggerInstance.prototype.limit = function (key, count) {
            if (this._limits[key]) {
                return this._limits[key];
            }
            else {
                return (this._limits[key] = new LoggerLimit(this, count));
            }
        };
        Object.defineProperty(LoggerInstance.prototype, "__all", {
            get: function () {
                return __assign({}, omni.registry);
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "__exclu", {
            get: function () {
                return omni.exclusive;
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(LoggerInstance.prototype, "__default", {
            get: function () {
                return rootInstance;
            },
            enumerable: false,
            configurable: true
        });
        LoggerInstance.prototype.log = function (logLevel) {
            var _a;
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            // eslint-disable-line @typescript-eslint/no-explicit-any
            if (omni.exclusive && omni.exclusive !== this)
                return;
            var _b = this._computedOptions, enabledOption = _b.enabled, stackOption = _b.stack, dateOption = _b.date, timeOption = _b.time, levelOption = _b.level, padOption = _b.pad;
            if (enabledOption && logLevel <= levelOption) {
                var methods = const_1.LEVEL_PARAMS[logLevel].methods;
                var prefix = [];
                var levelLabel = padOption && const_1.LEVEL_PARAMS[logLevel].paddedLabel
                    ? const_1.LEVEL_PARAMS[logLevel].paddedLabel
                    : const_1.LEVEL_PARAMS[logLevel].label;
                var debugPrefix = "" + levelLabel + (this._namespace ? " \"" + this._namespace + "\"" : "");
                if (timeOption) {
                    var currentTime = new Date().getTime();
                    if (this.lastLogTime) {
                        var timeDiff = (currentTime - this.lastLogTime) / 1000;
                        var timePrefix = "[+" + (timeDiff ? "" + timeDiff : "0.").padEnd(5, "0") + " s.]";
                        prefix.push(timePrefix);
                    }
                    this.lastLogTime = currentTime;
                }
                else {
                    this.lastLogTime = new Date().getTime();
                }
                if (inNode) {
                    var style = styles_1.LEVEL_STYLES[logLevel].style;
                    var levelPrefix = debugPrefix;
                    if (chalk) {
                        var colorize = chalk;
                        if (style.color)
                            colorize = colorize.keyword(style.color);
                        if (style.backgroundColor)
                            colorize = colorize.bgKeyword(style.backgroundColor);
                        levelPrefix = colorize(" " + debugPrefix + " ");
                    }
                    if (padOption) {
                        prefix.unshift(levelPrefix);
                    }
                    else {
                        prefix.push("[" + levelPrefix + "]");
                    }
                    try {
                        args = args.map(function (arg) {
                            return typeof arg === "object" && utilInspect ? utilInspect(arg, false, null, true) : arg;
                        });
                    }
                    catch (e) { } // eslint-disable-line
                }
                if (dateOption) {
                    var datePrefix = "[" + date_fns_1.format(new Date(), "yyyy-MM-dd kk:mm:ss.SSS") + "]";
                    prefix.unshift(datePrefix);
                }
                if (stackOption && stacktrace) {
                    var st = stacktrace.getSync();
                    var fName = (_a = st[2]) === null || _a === void 0 ? void 0 : _a.functionName;
                    if (fName)
                        prefix.push("< " + fName + " >");
                }
                if (inBrowser) {
                    prefix.unshift("%c" + debugPrefix, styles_1.LEVEL_STYLES[logLevel].css || "");
                }
                for (var _c = 0, methods_1 = methods; _c < methods_1.length; _c++) {
                    var method = methods_1[_c];
                    method.apply(void 0, __spreadArrays(prefix, args));
                }
            }
        };
        LoggerInstance.prototype.once = function (id) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            if (!this._onces[id]) {
                this._onces[id] = true;
                this.log(types_1.LogLevel.WHO_CARES, args);
            }
        };
        return LoggerInstance;
    }());
    var RootLoggerInstance = /** @class */ (function (_super) {
        __extends(RootLoggerInstance, _super);
        function RootLoggerInstance() {
            return _super.call(this) || this;
        }
        RootLoggerInstance.prototype.computeOptions = function () {
            for (var _i = 0, _a = Object.values(omni.registry); _i < _a.length; _i++) {
                var lgr = _a[_i];
                lgr.computeOptions();
            }
        };
        return RootLoggerInstance;
    }(LoggerInstance));
    Logger.ns = function (ns, options) {
        var _a;
        if (options === void 0) { options = {}; }
        if (!ns)
            return rootInstance;
        (_a = omni.registry)[ns] || (_a[ns] = new LoggerInstance(ns, options));
        return omni.registry[ns];
    };
    Logger.exclusive = function (lgr) {
        if (lgr)
            Logger.warn(lgr.namespace + " is exclusive");
        omni.exclusive = lgr;
    };
    var rootInstance = new RootLoggerInstance();
    Logger.log = function (level) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return rootInstance.log.apply(rootInstance, __spreadArrays([level], args));
    };
    Logger.emerg = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.emerg.apply(rootInstance, args);
    };
    Logger.alert = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.alert.apply(rootInstance, args);
    };
    Logger.crit = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.crit.apply(rootInstance, args);
    };
    Logger.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.error.apply(rootInstance, args);
    };
    Logger.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.warn.apply(rootInstance, args);
    };
    Logger.notice = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.notice.apply(rootInstance, args);
    };
    Logger.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.info.apply(rootInstance, args);
    };
    Logger.verb = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.verb.apply(rootInstance, args);
    };
    Logger.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.debug.apply(rootInstance, args);
    };
    Logger.wth = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return rootInstance.wth.apply(rootInstance, args);
    };
    Logger.once = function (id) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return rootInstance.once.apply(rootInstance, __spreadArrays([id], args));
    };
    Logger.limit = function (key, count) { return rootInstance.limit(key, count); };
    Logger.patch = function () {
        console.error = Logger.error;
        console.warn = Logger.warn;
        console.log = console.info = Logger.info;
        console.debug = Logger.debug;
    };
})(Logger = exports.Logger || (exports.Logger = {}));
