"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUtils = void 0;
var ArrayUtils;
(function (ArrayUtils) {
    function first(arr) {
        return arr[0];
    }
    ArrayUtils.first = first;
    function last(arr) {
        return arr[arr.length - 1];
    }
    ArrayUtils.last = last;
    function sum(arr) {
        var sum = 0;
        for (var _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
            var n = arr_1[_i];
            sum = sum + n;
        }
        return sum;
    }
    ArrayUtils.sum = sum;
    function max(arr) {
        var max = Number.NEGATIVE_INFINITY;
        for (var _i = 0, arr_2 = arr; _i < arr_2.length; _i++) {
            var n = arr_2[_i];
            max = max > n ? max : n;
        }
        return max;
    }
    ArrayUtils.max = max;
    function min(arr) {
        var min = Number.POSITIVE_INFINITY;
        for (var _i = 0, arr_3 = arr; _i < arr_3.length; _i++) {
            var n = arr_3[_i];
            min = min < n ? min : n;
        }
        return min;
    }
    ArrayUtils.min = min;
    function clearAdjacent(arr, keepFirst, compareFieldsOrFunction) {
        if (keepFirst === void 0) { keepFirst = true; }
        var lastValue;
        var result = [], workArray = keepFirst ? arr : __spreadArrays(arr).reverse();
        var compareFunction = function (t, c) { return t === c; };
        if (compareFieldsOrFunction) {
            if (typeof compareFieldsOrFunction === "function") {
                compareFunction = compareFieldsOrFunction;
            }
            else {
                var compareFields_1 = Array.isArray(compareFieldsOrFunction)
                    ? compareFieldsOrFunction
                    : [compareFieldsOrFunction];
                compareFunction = function (t, c) {
                    for (var _i = 0, compareFields_2 = compareFields_1; _i < compareFields_2.length; _i++) {
                        var k = compareFields_2[_i];
                        if (t[k] !== c[k])
                            return false;
                    }
                    return true;
                };
            }
        }
        for (var _i = 0, workArray_1 = workArray; _i < workArray_1.length; _i++) {
            var currentValue = workArray_1[_i];
            if (lastValue === undefined ||
                (!!compareFunction && !compareFunction(currentValue, lastValue)) ||
                (!compareFunction && currentValue !== lastValue)) {
                result.push(currentValue);
            }
            lastValue = currentValue;
        }
        return keepFirst ? result : result.reverse();
    }
    ArrayUtils.clearAdjacent = clearAdjacent;
    function sortFunction(fieldOrAccessor, desc) {
        var accessor = typeof fieldOrAccessor === "function"
            ? fieldOrAccessor
            : function (o) {
                var v = o;
                if (typeof fieldOrAccessor === "string") {
                    var path = fieldOrAccessor.split(".");
                    var prop = void 0;
                    while ((prop = path.shift())) {
                        v = v === null || v === void 0 ? void 0 : v[prop];
                    }
                }
                else {
                    v = o[fieldOrAccessor];
                }
                return v;
            };
        var comparableAccessor = function (o) {
            var v = accessor(o);
            switch (true) {
                case typeof v === "number" || typeof v === "string" || typeof v === "bigint":
                    return v;
                case v instanceof Date:
                    return v.getTime();
                case v === undefined || v === null:
                    return Number.NEGATIVE_INFINITY;
                case typeof v === "boolean":
                    return v ? 1 : 0;
                default:
                    return "" + v;
            }
        };
        var compareFunction = function (o1, o2) {
            var v1 = comparableAccessor(o1), v2 = comparableAccessor(o2);
            return (desc ? -1 : 1) * (v1 == v2 ? 0 : v1 < v2 ? -1 : 1);
        };
        return compareFunction;
    }
    ArrayUtils.sortFunction = sortFunction;
    function sortBy(arr, fieldOrAccessor, desc) {
        return arr.sort(sortFunction(fieldOrAccessor, desc));
    }
    ArrayUtils.sortBy = sortBy;
})(ArrayUtils = exports.ArrayUtils || (exports.ArrayUtils = {}));
