"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryPeriodType = void 0;
var HistoryPeriodType;
(function (HistoryPeriodType) {
    HistoryPeriodType["YEAR"] = "year";
    HistoryPeriodType["MONTH"] = "month";
    HistoryPeriodType["WEEK"] = "week";
    HistoryPeriodType["DAY"] = "day";
})(HistoryPeriodType = exports.HistoryPeriodType || (exports.HistoryPeriodType = {}));
