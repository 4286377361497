import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navbar } from '@blueprintjs/core';
import { LG } from 'big-l';
import classNames from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';
import semver from 'semver';
import IKon from './IKon';
const LGR = LG.ns('Header');
const defaultHeaderState = {};
class AppHeader extends Component {
    constructor() {
        super(...arguments);
        this.state = defaultHeaderState;
    }
    render() {
        const { icon, className, iconSize, background, config, onClickTitle } = this.props;
        if (!config)
            return null;
        const sem = config.version ? semver.parse(config.version) : null;
        return (_jsxs(Navbar, Object.assign({ className: classNames('bp3-dark', classNames), style: background ? { backgroundColor: background } : undefined, id: 'app-header' }, { children: [_jsxs(Navbar.Group, Object.assign({ className: classNames('app-name-group', { clickable: !!onClickTitle }), onClick: onClickTitle }, { children: [!!icon && _jsx(IKon, { className: 'app-icon', icon: icon, size: iconSize || 24 }, void 0), _jsxs("div", Object.assign({ className: 'app-title' }, { children: [_jsx("span", Object.assign({ className: 'app-prefix' }, { children: "Katal" }), void 0), _jsxs("span", Object.assign({ className: 'app-name' }, { children: [_jsx("span", { children: config.name }, void 0), sem && sem.major > 1 && _jsx("span", Object.assign({ className: 'app-version' }, { children: sem.major }), void 0)] }), void 0), sem && sem.prerelease.length && _jsx("span", Object.assign({ className: 'app-prerelease' }, { children: sem.prerelease[0] }), void 0)] }), void 0)] }), void 0), this.props.children ? (_jsxs(Navbar.Group, Object.assign({ className: 'header-content' }, { children: [_jsx(Navbar.Divider, {}, void 0), this.props.children] }), void 0)) : (_jsx("div", { className: 'spacer' }, void 0))] }), void 0));
    }
}
const mapState = (state) => ({
    config: state.app.localConfig,
});
const connector = connect(mapState);
export default connector(AppHeader);
