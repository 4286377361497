"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Admin = void 0;
var Data_1 = require("../Data");
var Admin;
(function (Admin) {
    Admin.MODULE = "trator";
    var IO;
    (function (IO) {
        IO.Type = Data_1.BaseData.IO.Type;
        IO.Status = Data_1.BaseData.IO.Status;
    })(IO = Admin.IO || (Admin.IO = {}));
    var Log;
    (function (Log) {
        Log.Level = Data_1.BaseData.Log.Level;
    })(Log = Admin.Log || (Admin.Log = {}));
    var Capability;
    (function (Capability) {
        Capability["ADMIN_KATAL"] = "admin_katal";
    })(Capability = Admin.Capability || (Admin.Capability = {}));
})(Admin = exports.Admin || (exports.Admin = {}));
