import { Nomenclature as KatalNomenclature } from './katal/Nomenclature';
import { Provider as KatalProvider } from './katal/Provider';
import { Store as KatalStore } from './katal/Store';
import { Trademark as KatalTrademark } from './katal/Trademark';
export var Katal;
(function (Katal) {
    Katal.Nomenclature = KatalNomenclature;
    Katal.Store = KatalStore;
    Katal.Provider = KatalProvider;
    Katal.Trademark = KatalTrademark;
})(Katal || (Katal = {}));
