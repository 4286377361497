var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LG, LT } from 'big-l';
import { Data } from '../../types/client';
import { AppActionType, setLocalConfig as setLocalConfig, setServerConfig, setStatus, setWorking } from './actions';
import AppClient from './client';
export const DEFAULT_LOADING_CHANNEL = '__default';
const channels = {};
const LGR = LG.ns('AppState');
export const getStatus = (module, refresh) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    LGR.debug('Get status for', module);
    const status = yield AppClient.getStatus(module);
    //TODO: set direction based on system
    const ios = status.ios.map(st => {
        var _a;
        const running = !!st.statuses.find(s => s.status == Data.IO.Status.RUNNING);
        const success = (_a = st.statuses.find(s => s.status == Data.IO.Status.SUCCESS)) === null || _a === void 0 ? void 0 : _a.date;
        const failStatus = st.statuses.find(s => s.status == Data.IO.Status.FAILED);
        let fail = failStatus === null || failStatus === void 0 ? void 0 : failStatus.date;
        if (fail && success && fail.getTime() < success.getTime())
            fail = undefined;
        const last = st.statuses.reduce((red, s) => red, undefined);
        return {
            type: st.type,
            direction: st.direction,
            running,
            success,
            last,
            fail,
            error: failStatus === null || failStatus === void 0 ? void 0 : failStatus.error,
        };
    });
    dispatch(setStatus(ios));
    // return status;
});
const setLoading = (loading, channel, silent = false) => ({
    type: AppActionType.SET_LOADING,
    loading,
    channel,
    silent,
});
export const startLoading = (channel = DEFAULT_LOADING_CHANNEL, silent) => dispatch => {
    LGR.verb('startLoading', channel);
    if (!channels[channel]) {
        channels[channel] = 1;
        dispatch(setLoading(true, channel, !!silent));
    }
    else {
        channels[channel] += 1;
    }
    return stopLoading(channel, silent);
};
const stopLoading = (channel = DEFAULT_LOADING_CHANNEL, silent) => dispatch => {
    LGR.verb('stopLoading', channel);
    let loadingChannel = channels[channel];
    if (loadingChannel > 1) {
        channels[channel] -= 1;
    }
    else {
        delete channels[channel];
        dispatch(setLoading(false, channel, !!silent));
    }
};
export const startWorking = () => dispatch => {
    dispatch(setWorking(true));
    return stopWorking();
};
const stopWorking = () => dispatch => {
    dispatch(setWorking(false));
};
export const execute = (cb, ...args) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const rest = dispatch(startWorking());
    yield LT.waitFor(1);
    const result = yield cb(...args);
    dispatch(rest);
    return result;
});
export const getServerConfig = (module) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const config = yield AppClient.getServerConfig(module);
    dispatch(setServerConfig(config));
    return config;
});
export const getLocalConfig = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const config = yield AppClient.getLocalConfig();
    LGR.debug('Got local config', config);
    if (config)
        dispatch(setLocalConfig(config));
    return config || {};
});
