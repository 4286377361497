"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LEVEL_STYLES = void 0;
var EnvUtils_1 = require("../utils/EnvUtils");
var ObjectUtils_1 = require("../utils/ObjectUtils");
var types_1 = require("./types");
var inBrowser = EnvUtils_1.EnvUtils.isBrowser();
// Constants
var DEFAULT_LEVEL_STYLE = {
    backgroundColor: "grey",
    color: "white",
};
var DEFAULT_BROWSER_STYLE = {
    padding: "2px 4px",
    "border-radius": "2px",
};
var levelStyles = (_a = {},
    _a[types_1.LogLevel.EMERGENCY] = {
        backgroundColor: "red",
    },
    _a[types_1.LogLevel.ALERT] = {
        backgroundColor: "red",
    },
    _a[types_1.LogLevel.CRITICAL] = {
        backgroundColor: "red",
    },
    _a[types_1.LogLevel.ERROR] = {
        backgroundColor: "red",
    },
    _a[types_1.LogLevel.WARNING] = {
        backgroundColor: "orange",
    },
    _a[types_1.LogLevel.NOTICE] = {
        backgroundColor: "blue",
    },
    _a[types_1.LogLevel.INFO] = {},
    _a[types_1.LogLevel.VERBOSE] = {
        backgroundColor: "green",
    },
    _a[types_1.LogLevel.DEBUG] = {
        backgroundColor: "yellow",
        color: "black",
    },
    _a[types_1.LogLevel.WHO_CARES] = {
        backgroundColor: "lightgray",
        color: "black",
    },
    _a);
exports.LEVEL_STYLES = ObjectUtils_1.ObjectUtils.map(levelStyles, function (styl) {
    var fullStyle = __assign(__assign({}, DEFAULT_LEVEL_STYLE), styl);
    return {
        style: fullStyle,
        css: inBrowser ? css(fullStyle) : undefined,
    };
});
function css(style) {
    // const style = LEVEL_STYLES[level];
    var STYLE_MAP = {
        backgroundColor: "background-color",
    };
    var cssObject = __assign({}, DEFAULT_BROWSER_STYLE);
    for (var _i = 0, _a = Object.entries(style); _i < _a.length; _i++) {
        var _b = _a[_i], styleKey = _b[0], styleValue = _b[1];
        var cssKey = STYLE_MAP[styleKey] || styleKey;
        cssObject[cssKey] = styleValue;
    }
    return Object.entries(cssObject)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return key + ": " + value;
    })
        .join(";");
}
