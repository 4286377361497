import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alignment, Button, Checkbox, Radio } from "@blueprintjs/core";
import { mdiClockOutline, mdiFunction, mdiTimerOutline } from "@mdi/js";
import { LG } from "big-l";
import classNames from "classnames";
import { Component } from "react";
import IKon from "../IKon";
const defaultLGTabState = {
    loggers: [],
};
const LOGGER_OPTIONS = {
    date: {
        label: "Date & time",
        icon: mdiClockOutline,
    },
    time: {
        label: "Elapsed time",
        icon: mdiTimerOutline,
    },
    stack: {
        label: "Call stack",
        icon: mdiFunction,
    },
};
class LGTab extends Component {
    constructor() {
        super(...arguments);
        this.state = defaultLGTabState;
    }
    componentDidMount() {
        this.refreshLoggers();
    }
    refreshLoggers() {
        const anyLG = LG.ns();
        this.setState({ loggers: [anyLG, ...Object.values(anyLG.__all)], exclusiveLogger: anyLG.__exclu });
    }
    render() {
        const { loggers, exclusiveLogger } = this.state;
        return (_jsx("div", Object.assign({ className: "loggers" }, { children: loggers
                .sort((l1, l2) => l1.namespace === undefined
                ? -1
                : l2.namespace === undefined
                    ? 1
                    : l1.namespace > l2.namespace
                        ? 1
                        : -1)
                .map((lgr, i) => (_jsxs("div", Object.assign({ className: classNames({
                    disabled: !lgr.enabled || (exclusiveLogger && lgr !== exclusiveLogger),
                }) }, { children: [_jsx("div", Object.assign({ className: "namespace" }, { children: lgr.namespace === undefined ? "[ default ]" : lgr.namespace }), void 0), _jsx("div", Object.assign({ className: "options" }, { children: Object.keys(LOGGER_OPTIONS).map((opt) => (_jsx(Button, { active: !!lgr[opt], icon: _jsx(IKon, { icon: LOGGER_OPTIONS[opt].icon, size: 14 }, void 0), onClick: () => {
                                lgr[opt] = !lgr[opt];
                                LG.debug("Set", opt, "to", lgr[opt]);
                                this.refreshLoggers();
                            } }, `bt-${opt}`))) }), void 0), _jsx("div", Object.assign({ className: "enable" }, { children: _jsx(Checkbox, { label: "enabled ?", alignIndicator: Alignment.RIGHT, checked: lgr.enabled, onChange: (evt) => {
                                lgr.enabled = evt.currentTarget.checked;
                                if (lgr.exclusive && !lgr.enabled)
                                    lgr.exclusive = false;
                                this.refreshLoggers();
                            } }, void 0) }), void 0), _jsx("div", Object.assign({ className: "exclusive" }, { children: _jsx(Radio, { label: "exclusive ?", alignIndicator: Alignment.RIGHT, checked: lgr.enabled && lgr.exclusive, disabled: !lgr.enabled, onClick: (evt) => {
                                lgr.exclusive = !lgr.exclusive;
                                this.refreshLoggers();
                            }, onChange: () => null }, void 0) }), void 0)] }), `lgr-${i}`))) }), void 0));
    }
}
export default LGTab;
