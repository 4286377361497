var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTPClient } from '../../net/HTTPClient';
export default class AppClient extends HTTPClient {
    static getServerConfig(module) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.get(`/config${module ? '/' + module : ''}`)
                .then(result => result.data || {})
                .catch(e => ({}));
        });
    }
    static getStatus(module) {
        return __awaiter(this, void 0, void 0, function* () {
            const [ios] = yield Promise.all([
                this.get(`/status${module ? '/' + module : ''}`)
                    .then(result => result.data || {})
                    .catch(e => []),
            ]);
            return { ios };
            // return this.get(`/status${module ? '/' + module : ''}`)
            // 	.then(result => result.data || {})
            // 	.catch(e => ({}));
        });
    }
}
