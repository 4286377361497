var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@blueprintjs/core';
import { mdiTestTube } from '@mdi/js';
import { LG } from 'big-l';
import { ExcelClient, WebWorker } from 'katal3-client';
import { Component } from 'react';
import IKon from '../IKon';
const LGR = LG.ns('PlaygroundTab');
const defaultPlaygroundTabState = {};
class TestClient extends WebWorker.Client {
    test() {
        return this.call('test');
    }
}
class PlaygroundTab extends Component {
    constructor() {
        super(...arguments);
        this.state = defaultPlaygroundTabState;
    }
    handleTest(num) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (num) {
                case 1:
                    const client1 = new TestClient('/workers/test.js');
                    LGR.debug(yield client1.test());
                    break;
                case 2:
                    const client2 = new ExcelClient();
                    LGR.debug(yield client2.buffer([]));
                    break;
            }
        });
    }
    render() {
        return (_jsxs("div", { children: [_jsx(Button, Object.assign({ icon: _jsx(IKon, { icon: mdiTestTube, size: 20 }, void 0), onClick: () => this.handleTest(1) }, { children: "TEST 1" }), void 0), _jsx(Button, Object.assign({ icon: _jsx(IKon, { icon: mdiTestTube, size: 20 }, void 0), onClick: () => this.handleTest(2) }, { children: "TEST 2" }), void 0)] }, void 0));
    }
}
export default PlaygroundTab;
