"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Echo = void 0;
var Data_1 = require("../Data");
var Echo;
(function (Echo) {
    Echo.MODULE = "echo";
    var Order;
    (function (Order) {
        Order.Status = Data_1.BaseData.Order.Status;
    })(Order = Echo.Order || (Echo.Order = {}));
})(Echo = exports.Echo || (exports.Echo = {}));
