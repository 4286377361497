"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberUtils = void 0;
var NumberUtils;
(function (NumberUtils) {
    NumberUtils.GOLDEN_RATIO = 0.618033988749895;
    function finite(num, or) {
        if (num === undefined ||
            num === null ||
            !isNaN(num) ||
            num == Number.POSITIVE_INFINITY ||
            num == Number.NEGATIVE_INFINITY)
            return or;
        return num;
    }
    NumberUtils.finite = finite;
    function round(value, precision) {
        if (precision === void 0) { precision = 1; }
        if (precision == 1)
            return Math.round(value);
        return Math.round(value / precision) * precision;
    }
    NumberUtils.round = round;
    function reduce(price, precision) {
        var mod = price % precision;
        return price - mod + (mod < precision / 2 ? 0 : precision);
    }
    NumberUtils.reduce = reduce;
    function minmax(options) {
        var nums = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            nums[_i - 1] = arguments[_i];
        }
        var min = Number.POSITIVE_INFINITY, max = Number.NEGATIVE_INFINITY, count = nums.length, sum = 0;
        for (var i = count; i >= 0; min = nums[i] < min ? nums[i] : min, max = nums[i] > max ? nums[i] : max, i--) {
            if ((options === null || options === void 0 ? void 0 : options.sum) || (options === null || options === void 0 ? void 0 : options.sum))
                sum += nums[i];
        }
        var result = { min: min, max: max };
        if (options === null || options === void 0 ? void 0 : options.avg)
            result.avg = sum / count;
        if (options === null || options === void 0 ? void 0 : options.sum)
            result.sum = sum;
        return result;
    }
    NumberUtils.minmax = minmax;
    // export function addPercent(price: number, percent: number, decimals: number | null = null): number {
    // 	const newPrice = price * (1 + percent);
    // 	return decimals === null ? newPrice : round(newPrice, decimals);
    // }
    // export function subtrPercent(price: number, tax: number): number {
    // 	return price / (1 + tax);
    // }
    // export function applyMargin(price: number, percent: number, decimals = 0): number {
    // 	const newPrice = price / (1 - percent);
    // 	return round(newPrice, decimals);
    // }
    // export function getMargin(buyPrice: number, sellPrice: number, tax = 0): number {
    // 	const sell = tax ? subtrPercent(sellPrice, tax) : sellPrice;
    // 	return 1 - buyPrice / sell;
    // }
    // export function getPercentDifference(newPrice: number, oldPrice: number): number {
    // 	return (newPrice - oldPrice) / oldPrice;
    // }
})(NumberUtils = exports.NumberUtils || (exports.NumberUtils = {}));
