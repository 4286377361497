import { LO } from 'big-l';
import zxcvbn from 'zxcvbn';
export class FormUtils {
    static validate(o, fields) {
        let errors = {};
        fields = fields || {};
        for (let k in fields) {
            if (!fields[k])
                continue;
            let v = fields[k].validation(o[k]);
            if (!v.success) {
                errors[k] = { message: fields[k].error, detail: v.detail, value: o[k] };
            }
        }
        return { success: LO.isEmpty(errors), errors };
    }
}
FormUtils.isPasswordStrong = (strength) => (value) => {
    let score = zxcvbn(value || '').score;
    return { success: score >= strength, detail: score };
};
FormUtils.minLength = (len) => (value) => {
    return { success: (value === null || value === void 0 ? void 0 : value.length) >= len };
};
FormUtils.required = () => (value) => {
    return { success: !!value && value.length > 0, detail: { empty: value === undefined } };
};
FormUtils.isEmail = () => (value) => {
    let rx = /^[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}$/i;
    return { success: rx.test(value) };
};
FormUtils.equals = (compare) => (value) => {
    return { success: value == compare };
};
