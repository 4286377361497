import { isDateValid } from "@blueprintjs/datetime/lib/esm/common/dateUtils";
import { LS } from "big-l";
import { add, format, isSameYear } from "date-fns";
import { startOfWeek } from "date-fns/esm";
import fr from "date-fns/locale/fr";
import { LocaleUtils } from "react-day-picker";
const WEEKDAYS_SHORT = new Array(7)
    .fill(0)
    .map((_, d) => format(add(startOfWeek(new Date()), { days: d }), "E", { locale: fr }))
    .map((d) => LS.capitalize(d.substr(0, 2)));
const WEEKDAYS_LONG = new Array(7)
    .fill(0)
    .map((_, d) => format(add(startOfWeek(new Date()), { days: d }), "EEEE", { locale: fr }))
    .map((d) => LS.capitalize(d));
const MONTHS = new Array(12)
    .fill(0)
    .map((_, m) => format(new Date(1970, m), "MMM", { locale: fr }))
    .map((m) => LS.capitalize(m));
const FIRST = startOfWeek(new Date(), { locale: fr }).getDay();
export var DateUtils;
(function (DateUtils) {
    function displayDate(dte) {
        return isDateValid(dte) ? format(dte, isSameYear(dte, new Date()) ? "dd/MM" : "dd/MM/yy") : "---";
    }
    DateUtils.displayDate = displayDate;
    DateUtils.localeUtil = Object.assign(Object.assign({}, LocaleUtils), { formatDay: (day) => format(day, "dd", { locale: fr }), formatMonthTitle: (month, locale) => format(month, "MM", { locale: fr }), formatWeekdayLong: (weekday, locale) => WEEKDAYS_LONG[weekday], formatWeekdayShort: (weekday, locale) => WEEKDAYS_SHORT[weekday], getFirstDayOfWeek: (locale) => FIRST, getMonths: (locale) => MONTHS });
})(DateUtils || (DateUtils = {}));
