import { LO } from 'big-l';
export var ComponentUtils;
(function (ComponentUtils) {
    function diff(component, newProps, newState) {
        return {
            propsDiff: LO.changed(component.props, newProps),
            stateDiff: newState ? LO.changed(component.state, newState) : {},
        };
    }
    ComponentUtils.diff = diff;
    function debug(component, newProps, newState, detail) {
        const { propsDiff, stateDiff } = diff(component, newProps, newState);
        if (LO.isEmpty(propsDiff) && LO.isEmpty(stateDiff))
            return [];
        const trace = [];
        trace.push(['** Component debug **']);
        if (detail) {
            if (!LO.isEmpty(propsDiff)) {
                trace.push(['--props']);
                Object.keys(propsDiff).forEach(k => {
                    trace.push([k, component.props[k], newProps[k]]);
                });
            }
            if (!LO.isEmpty(stateDiff)) {
                trace.push(['--state']);
                Object.keys(stateDiff).forEach(k => {
                    trace.push([k, component.state[k], newState[k]]);
                });
            }
        }
        else {
            if (!LO.isEmpty(propsDiff))
                trace.push(['--props', Object.keys(propsDiff).join(',')]);
            if (!LO.isEmpty(stateDiff))
                trace.push(['--state', Object.keys(stateDiff).join(',')]);
        }
        trace.push(['*****']);
        return trace;
    }
    ComponentUtils.debug = debug;
})(ComponentUtils || (ComponentUtils = {}));
