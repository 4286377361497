export var AppActionType;
(function (AppActionType) {
    AppActionType["SET_LOADING"] = "APP/SET_LOADING";
    AppActionType["SET_LOCAL_CONFIG"] = "APP/SET_INFOS";
    AppActionType["SET_READY"] = "APP/SET_READY";
    AppActionType["SET_SERVER_CONFIG"] = "APP/SET_CONFIG";
    AppActionType["SET_WORKING"] = "APP/SET_WORKING";
    AppActionType["SET_STATUS"] = "APP/SET_STATUS";
})(AppActionType || (AppActionType = {}));
export const setLocalConfig = (config) => ({
    type: AppActionType.SET_LOCAL_CONFIG,
    config,
});
export const setReady = () => ({
    type: AppActionType.SET_READY,
});
export const setServerConfig = (config) => ({
    type: AppActionType.SET_SERVER_CONFIG,
    config,
});
export const setWorking = (working) => ({
    type: AppActionType.SET_WORKING,
    working,
});
export const setStatus = (ios) => ({
    type: AppActionType.SET_STATUS,
    ios,
});
