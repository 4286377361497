// import { adminReducer } from './admin/reducer';
import { App, Auth } from 'katal3-client';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { SplashState } from './types';

const store = createStore(
	combineReducers<SplashState>({
		app: App.reducer,
		auth: Auth.reducer,
	}),
	applyMiddleware(thunk, Auth.middleware)
);

export default store;
