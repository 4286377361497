"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromiseUtils = void 0;
var PromiseUtils;
(function (PromiseUtils) {
    var DEFAULT_CONCURRENCY = 10;
    var DEFAULT_NAME = "pool";
    var PoolErrorImpl = /** @class */ (function (_super) {
        __extends(PoolErrorImpl, _super);
        function PoolErrorImpl(message, catched) {
            var _this = _super.call(this, message) || this;
            _this.catched = catched;
            return _this;
        }
        return PoolErrorImpl;
    }(Error));
    var PromisePool = /** @class */ (function () {
        function PromisePool(options) {
            var _this = this;
            this.currentIndex = 0;
            this.running = [];
            this.enqueued = [];
            this.result = [];
            this.isClosed = false;
            this.isDone = false;
            this.size = (options === null || options === void 0 ? void 0 : options.concurrency) || DEFAULT_CONCURRENCY;
            this.name = (options === null || options === void 0 ? void 0 : options.name) || DEFAULT_NAME;
            this.options = options;
            this.poolPromise = new Promise(function (res, rej) {
                _this.poolResolve = res;
                _this.poolReject = rej;
            });
        }
        PromisePool.prototype.enqueue = function (promiseGenerator) {
            if (this.isClosed)
                throw new Error("[" + this.name + "] PromisePool already closed");
            if (this.isDone)
                throw new Error("[" + this.name + "] PromisePool already performed");
            this.log("Enqueue promise@" + this.currentIndex);
            this.enqueued.push({ index: this.currentIndex++, generator: promiseGenerator });
            this.runNext();
        };
        PromisePool.prototype.log = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.verbose)
                console.debug.apply(console, __spreadArrays(["[" + this.name + "]"], args));
        };
        PromisePool.prototype.warn = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.verbose)
                console.warn.apply(console, __spreadArrays(["[" + this.name + "]"], args));
        };
        PromisePool.prototype.error = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.verbose)
                console.error.apply(console, __spreadArrays(["[" + this.name + "]"], args));
        };
        PromisePool.prototype.runNext = function () {
            var _this = this;
            if (this.enqueued.length) {
                var _loop_1 = function () {
                    var nextQueuedPromise = this_1.enqueued.shift();
                    this_1.log("Run promise " + (nextQueuedPromise === null || nextQueuedPromise === void 0 ? void 0 : nextQueuedPromise.index));
                    if (nextQueuedPromise) {
                        var nextPromise_1 = nextQueuedPromise.generator();
                        nextPromise_1
                            .then(function (res) { return _this.promiseDone(nextPromise_1, nextQueuedPromise.index, res); })
                            .catch(function (err) { return _this.promiseRejected(nextPromise_1, nextQueuedPromise.index, err); });
                        this_1.running.push(nextPromise_1);
                    }
                };
                var this_1 = this;
                while (this.running.length < this.size && !!this.enqueued.length) {
                    _loop_1();
                }
            }
            else if (!this.running.length) {
                if (this.isClosed) {
                    this.log("No more queue: resolving");
                    this.isDone = true;
                    this.poolResolve(this.result);
                }
                else {
                    this.log("Waiting for new promises or close instruction");
                }
            }
            else {
                this.log(this.running.length + " promises still running");
            }
        };
        Object.defineProperty(PromisePool.prototype, "promise", {
            get: function () {
                return this.poolPromise;
            },
            enumerable: false,
            configurable: true
        });
        PromisePool.prototype.promiseDone = function (p, result, index) {
            if (this.isDone)
                return;
            var promiseIndex = this.running.indexOf(p);
            if (promiseIndex >= 0) {
                this.running.splice(promiseIndex, 1);
                this.result[index] = result;
                this.log("Promise " + index + " done with", typeof result);
                this.runNext();
            }
            else {
                this.warn("Unknown promise resolved");
            }
        };
        PromisePool.prototype.promiseRejected = function (p, index, error) {
            var _a;
            if (this.isDone)
                return;
            var promiseIndex = this.running.indexOf(p);
            if (promiseIndex >= 0) {
                this.running.splice(promiseIndex, 1);
                this.result[index] = new PoolErrorImpl("Promise " + index + " was rejected", error);
                if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.rejectOnError) {
                    this.isDone = true;
                    this.poolReject(error);
                }
                else {
                    this.runNext();
                }
                this.error("Promise " + index + " error", error);
            }
            else {
                this.warn("Unknown promise resolved");
            }
        };
        Object.defineProperty(PromisePool.prototype, "pending", {
            get: function () {
                return this.enqueued.length;
            },
            enumerable: false,
            configurable: true
        });
        PromisePool.prototype.close = function () {
            this.log("Close pool");
            this.isClosed = true;
            this.runNext();
            return this.poolPromise;
        };
        return PromisePool;
    }());
    function pool(concurrency, name) {
        if (concurrency === void 0) { concurrency = 10; }
        return new PromisePool({ concurrency: concurrency, name: name });
    }
    PromiseUtils.pool = pool;
    function parallel(commands, options) {
        if (!commands.length)
            return Promise.resolve([]);
        var parallelPool = new PromisePool(__assign({ concurrency: Number.POSITIVE_INFINITY }, options));
        for (var _i = 0, commands_1 = commands; _i < commands_1.length; _i++) {
            var cmd = commands_1[_i];
            parallelPool.enqueue(cmd);
        }
        return parallelPool.close();
    }
    PromiseUtils.parallel = parallel;
    function serie(commands, options) {
        if (!commands.length)
            return Promise.resolve([]);
        var parallelPool = new PromisePool(__assign(__assign({}, options), { concurrency: 1 }));
        for (var _i = 0, commands_2 = commands; _i < commands_2.length; _i++) {
            var cmd = commands_2[_i];
            parallelPool.enqueue(cmd);
        }
        return parallelPool.close();
    }
    PromiseUtils.serie = serie;
})(PromiseUtils = exports.PromiseUtils || (exports.PromiseUtils = {}));
