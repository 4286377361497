import { LA, LG } from 'big-l';
import { AppActionType } from './actions';
const defaultAppState = {
    // initialized: true,
    ready: false,
    loading: false,
    loadings: {},
    working: false,
    ios: [],
};
const LGR = LG.ns('AppState');
LGR.enabled = false;
const loadingCount = {};
const silentCount = {};
let workingCount = 0;
export const appReducer = (state = defaultAppState, action) => {
    let newState = state;
    switch (action.type) {
        case AppActionType.SET_LOCAL_CONFIG:
            const { config: localConfig } = action;
            newState = Object.assign(Object.assign({}, state), { localConfig, loading: false });
            break;
        case AppActionType.SET_READY:
            if (!state.ready) {
                newState = Object.assign(Object.assign({}, state), { ready: true });
            }
            break;
        case AppActionType.SET_LOADING:
            const lAction = action;
            const { loading, channel, silent } = lAction;
            const counts = silent ? silentCount : loadingCount;
            if (loading) {
                if (!counts[channel]) {
                    LGR.verb('Start loading', channel);
                    counts[channel] = 0;
                    if (!silent) {
                        newState = Object.assign(Object.assign({}, newState), { loading: true, loadings: Object.assign(Object.assign({}, state.loadings), { [channel]: true }) });
                    }
                    else if (!newState.loading) {
                        newState = Object.assign(Object.assign({}, newState), { loading: true });
                    }
                }
                counts[channel] += 1;
            }
            else {
                if (!newState.loading)
                    LGR.warn('Stop loading on already not loading state!');
                if (counts[channel]) {
                    counts[channel] -= 1;
                    if (!counts[channel]) {
                        delete counts[channel];
                        if (!silent) {
                            const newLoadings = Object.assign({}, newState.loadings);
                            if (newLoadings[channel]) {
                                delete newLoadings[channel];
                                newState = Object.assign(Object.assign({}, newState), { loadings: newLoadings });
                            }
                        }
                    }
                    const isLoading = LA.sum([...Object.values(loadingCount), ...Object.values(silentCount)]);
                    if (!isLoading) {
                        newState = Object.assign(Object.assign({}, newState), { loading: false });
                    }
                }
            }
            break;
        case AppActionType.SET_SERVER_CONFIG:
            const { config: serverConfig } = action;
            LGR.debug('Config', serverConfig);
            newState = Object.assign(Object.assign({}, state), { serverConfig });
            break;
        case AppActionType.SET_WORKING:
            const { working } = action;
            LGR.debug('>> Set working', working, workingCount, state.working);
            workingCount += working ? 1 : -1;
            newState = Object.assign(Object.assign({}, state), { working: workingCount > 0 });
            LGR.debug('<< Working is now', workingCount, newState.working);
            break;
        case AppActionType.SET_STATUS:
            const { ios } = action;
            newState = Object.assign(Object.assign({}, state), { ios });
            break;
    }
    LGR.debug('State is now', newState);
    return newState;
};
