import { computeSheets } from './middleware';
import * as ExcelTypes from './types';
export var Excel;
(function (Excel) {
    Excel.EXT = ExcelTypes.EXT;
    Excel.CellType = ExcelTypes.CellType;
    Excel.CellFormat = ExcelTypes.CellFormat;
    Excel.Color = ExcelTypes.Color;
    Excel.colorize = ExcelTypes.colorize;
    Excel.compute = computeSheets;
})(Excel || (Excel = {}));
