import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import StatusBar from './StatusBar';
const defaultAppLayoutState = {};
export class AppLayout extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = defaultAppLayoutState;
    }
    render() {
        const { header, className, status, children } = this.props;
        return (_jsxs(_Fragment, { children: [header, _jsx("div", Object.assign({ id: 'app-content', className: className }, { children: children }), void 0), !!status && _jsx(StatusBar, {}, void 0)] }, void 0));
    }
}
