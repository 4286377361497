"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LEVEL_PARAMS = exports.DEFAULT_LOGGER_OPTIONS = void 0;
var EnvUtils_1 = require("../utils/EnvUtils");
var types_1 = require("./types");
var inNode = EnvUtils_1.EnvUtils.isNode();
exports.DEFAULT_LOGGER_OPTIONS = {
    enabled: true,
    stack: false,
    date: false,
    time: false,
    level: types_1.LogLevel.WHO_CARES,
    pad: true,
};
exports.LEVEL_PARAMS = (_a = {},
    _a[types_1.LogLevel.EMERGENCY] = {
        label: "EMERGENCY",
        methods: [console.error, console.trace],
    },
    _a[types_1.LogLevel.ALERT] = {
        label: "ALERT",
        methods: [console.error, console.trace],
    },
    _a[types_1.LogLevel.CRITICAL] = {
        label: "CRITICAL",
        methods: [console.error, console.trace],
    },
    _a[types_1.LogLevel.ERROR] = {
        label: "ERROR",
        methods: [console.error],
    },
    _a[types_1.LogLevel.WARNING] = {
        label: "WARNING",
        methods: [console.warn],
    },
    _a[types_1.LogLevel.NOTICE] = {
        label: "NOTICE",
        methods: [console.info],
    },
    _a[types_1.LogLevel.INFO] = {
        label: "INFO",
        methods: [console.info],
    },
    _a[types_1.LogLevel.VERBOSE] = {
        label: "VERBOSE",
        methods: [console.debug],
    },
    _a[types_1.LogLevel.DEBUG] = {
        label: "DEBUG",
        methods: [console.debug],
    },
    _a[types_1.LogLevel.WHO_CARES] = {
        label: "WHO CARES?",
        methods: [console.debug],
    },
    _a);
if (inNode) {
    var padSize = Math.max.apply(Math, Object.values(exports.LEVEL_PARAMS).map(function (info) { return info.label.length; }));
    for (var _i = 0, _b = Object.values(exports.LEVEL_PARAMS); _i < _b.length; _i++) {
        var lvl = _b[_i];
        lvl.paddedLabel = lvl.label
            .padEnd(lvl.label.length + (padSize - lvl.label.length) / 2, " ")
            .padStart(padSize, " ");
    }
}
