// export type AuthModule = {
// 	code: string;
// 	name: string;
// 	path: string;
// 	role: string;
// 	level: number;
// };
export var AuthErrorType;
(function (AuthErrorType) {
    AuthErrorType["WRONG_CREDENTIALS"] = "wrong_credentials";
    AuthErrorType["NOT_ALLOWED"] = "not_allowed";
})(AuthErrorType || (AuthErrorType = {}));
export class AuthError extends Error {
    constructor(type, message) {
        super(message);
        this.type = type;
    }
}
