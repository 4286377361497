import { CellType } from './types';
function computeDatas(title, datas, columns) {
    const lines = [], computedColumns = [], columnsFunctions = [];
    let headerDone = false;
    for (const data of datas) {
        const lineColumns = [];
        if (!headerDone) {
            for (const col of columns) {
                computedColumns.push({ header: col.header, width: col.width });
                const valueFieldOrFunction = col.value;
                const valueFunction = typeof valueFieldOrFunction === 'string'
                    ? (o) => o[valueFieldOrFunction]
                    : valueFieldOrFunction || ((o) => `${o}`);
                const typeValueOrFunction = col.type;
                const typeFunction = typeof typeValueOrFunction === 'string'
                    ? (o) => typeValueOrFunction
                    : typeValueOrFunction || ((o) => undefined);
                const formatValueOrFunction = col.format;
                const formatFunction = typeof formatValueOrFunction === 'string'
                    ? (o) => formatValueOrFunction
                    : formatValueOrFunction || ((o) => undefined);
                const spanValueOrFunction = col.span;
                const spanFunction = typeof spanValueOrFunction === 'number'
                    ? (o) => spanValueOrFunction
                    : spanValueOrFunction || ((o) => undefined);
                columnsFunctions.push({ valueFunction, typeFunction, formatFunction, spanFunction });
            }
            headerDone = true;
        }
        for (const col of columnsFunctions) {
            let value = col.valueFunction(data), type = col.typeFunction(data), format = col.formatFunction(data), span = col.spanFunction(data);
            if (!type) {
                switch (true) {
                    case typeof value === 'number':
                        type = CellType.NUMBER;
                        break;
                    case value instanceof Date:
                        type = CellType.DATE;
                        break;
                }
            }
            if ((value == Infinity || value == -Infinity) && type == CellType.NUMBER) {
                value = '∞';
                type = CellType.TEXT;
            }
            lineColumns.push({ value, type, format, span });
        }
        lines.push(lineColumns);
    }
    return { title, columns: computedColumns, lines };
}
export function computeSheets(dataOrSheetsOrComputed, columns, singleSheetTitle) {
    var _a;
    let sheets = [];
    if ((_a = dataOrSheetsOrComputed[0]) === null || _a === void 0 ? void 0 : _a.lines) {
        sheets = dataOrSheetsOrComputed;
    }
    else if (Array.isArray(columns)) {
        sheets = [computeDatas(singleSheetTitle, dataOrSheetsOrComputed, columns)];
    }
    else {
        sheets = dataOrSheetsOrComputed.map(sh => computeDatas(sh.title, sh.data, sh.columns));
    }
    return sheets;
    // return createWorkbookFromComputed(sheets);
}
