export class Model {
    constructor(o = {}) {
        let defaultProps = {};
        let defaultPropsSetter = this.constructor['defaultProps'];
        if (typeof defaultPropsSetter == 'function')
            defaultProps = defaultPropsSetter();
        Object.assign(this, Object.assign({}, defaultProps));
        this.update(o);
    }
    static getInstance(id) {
        const modelName = this.name;
        const modelRegistry = (Model._registry[modelName] = Model._registry[modelName] || {});
        return modelRegistry[`${id}`];
    }
    static getOrCreate(o, update) {
        const id = typeof o === 'object' ? o._id : o;
        const obj = typeof o === 'object' ? Object.assign({}, o) : { _id: id };
        const modelName = this.name;
        const modelRegistry = (Model._registry[modelName] =
            Model._registry[modelName] || {});
        let registryInstance = modelRegistry[id];
        if (!registryInstance)
            registryInstance = modelRegistry[`${id}`] = this.create(obj);
        else if (update)
            registryInstance.update(o);
        if (id)
            return registryInstance;
    }
    static create(o = {}) {
        return new this(o);
    }
    static getAll() {
        const modelName = this.name;
        const modelRegistry = (Model._registry[modelName] =
            Model._registry[modelName] || {});
        return Object.values(modelRegistry);
    }
    static find(o) {
        const modelName = this.name;
        const modelRegistry = (Model._registry[modelName] =
            Model._registry[modelName] || {});
        for (let mod in modelRegistry) {
            let inst = modelRegistry[mod];
            for (let k in o) {
                if (o[k] != inst[k])
                    continue;
                return inst;
            }
        }
    }
    static get registry() {
        return Model._registry[this.name] || {};
    }
    static clear() {
        if (this == Model)
            Model._registry = {};
        else
            Model._registry[this.name] = {};
    }
    update(o) {
        Object.assign(this, o);
    }
    clone(props) {
        let cloned = new this.constructor({});
        Object.assign(cloned, Object.assign(Object.assign({}, this), props));
        return cloned;
    }
}
Model._registry = {};
