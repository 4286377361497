"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IODirection = exports.IOStatus = exports.IOType = void 0;
var IOType;
(function (IOType) {
    IOType["ARTICLES"] = "articles";
    IOType["MONTH_HISTORY"] = "month_history";
    IOType["WEEK_HISTORY"] = "week_history";
    IOType["PROVIDERS"] = "providers";
    IOType["STOCKS"] = "stocks";
    IOType["ORDERS"] = "orders";
    IOType["PRICE_UPDATES"] = "price_updates";
    IOType["TILL"] = "till";
    IOType["RESULTS"] = "results";
})(IOType = exports.IOType || (exports.IOType = {}));
var IOStatus;
(function (IOStatus) {
    IOStatus["RUNNING"] = "running";
    IOStatus["FAILED"] = "failed";
    IOStatus["SUCCESS"] = "success";
    IOStatus["DRY"] = "dry";
})(IOStatus = exports.IOStatus || (exports.IOStatus = {}));
var IODirection;
(function (IODirection) {
    IODirection["IN"] = "in";
    IODirection["OUT"] = "out";
})(IODirection = exports.IODirection || (exports.IODirection = {}));
