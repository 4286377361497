var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { LG } from 'big-l';
import CryptoJS from 'crypto-js';
import { HTTPClient } from '../../net/HTTPClient';
const LGR = LG.ns('AuthClient');
class AuthUserImpl {
    constructor(profile, module) {
        var _a;
        this.dev = false;
        this._id = profile._id;
        this.company = profile.company;
        this.login = profile.login;
        this.email = profile.email;
        this.profile = profile.profile;
        this.dev = profile.dev;
        this.modules = profile.modules.map(mod => {
            const { capabilities } = mod, data = __rest(mod, ["capabilities"]);
            return data;
        });
        this.capabilities =
            ((_a = profile.modules
                .find(m => m.uid == module)) === null || _a === void 0 ? void 0 : _a.capabilities.reduce((red, cap) => (Object.assign(Object.assign({}, red), { [cap]: true })), {})) || {};
        LGR.debug('AuthUser is', this);
    }
    can(capability, ...alternativeCapabilities) {
        for (const cap of [capability, ...alternativeCapabilities])
            if (this.capabilities[cap])
                return true;
        return false;
    }
    isDev() {
        return this.dev;
    }
}
export default class AuthClient extends HTTPClient {
    static login(loginOrEmail, password, module, stay = false) {
        return __awaiter(this, void 0, void 0, function* () {
            let hashed = CryptoJS.SHA256(password).toString(CryptoJS.enc.Base64);
            let response = yield this.post(`/auth`, {
                login: loginOrEmail,
                pass: hashed,
                module,
                stay,
            });
            let { profile, token } = response.data;
            switch (response === null || response === void 0 ? void 0 : response.status) {
                case 200:
                    this.setToken(token, true);
                    if (profile) {
                        return new AuthUserImpl(profile, module);
                    }
                    break;
            }
            return;
        });
    }
    static loadProfile(module) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.token) {
                let response = yield this.get(`/auth/me`, {
                    params: { module },
                });
                let profile = response.data;
                return profile ? new AuthUserImpl(profile, module) : null;
            }
            return null;
        });
    }
    static loadUserData(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/me/data/${key || ''}`).then(result => result.data);
        });
    }
    static setUserData(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/me/data/${key}`, value).then(result => result.data);
        });
    }
    static logout() {
        this.setToken(null);
        return Promise.resolve();
    }
}
