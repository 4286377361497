"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticleStatus = void 0;
var ArticleStatus;
(function (ArticleStatus) {
    ArticleStatus["UNKNOWN"] = "unknown";
    ArticleStatus["ACTIVE"] = "active";
    ArticleStatus["INACTIVE"] = "inactive";
    ArticleStatus["CANCELED"] = "canceled";
    ArticleStatus["DELETED"] = "deleted";
})(ArticleStatus = exports.ArticleStatus || (exports.ArticleStatus = {}));
var StoreInfoType;
(function (StoreInfoType) {
    StoreInfoType["PRICE"] = "price";
    StoreInfoType["DPA"] = "dpa";
    StoreInfoType["STATUS"] = "status";
    StoreInfoType["PAMP"] = "pamp";
    StoreInfoType["LOCATION"] = "location";
    StoreInfoType["CATEGORY"] = "category";
    StoreInfoType["STOCK"] = "stock";
    StoreInfoType["COEF"] = "coef";
})(StoreInfoType || (StoreInfoType = {}));
var StoreInfoTypeRegistry = (_a = {},
    _a[StoreInfoType.PRICE] = 0,
    _a[StoreInfoType.DPA] = 0,
    _a[StoreInfoType.STATUS] = ArticleStatus.UNKNOWN,
    _a[StoreInfoType.PAMP] = 0,
    _a[StoreInfoType.LOCATION] = '',
    _a[StoreInfoType.CATEGORY] = '',
    _a[StoreInfoType.STOCK] = 0,
    _a[StoreInfoType.COEF] = 0,
    _a);
