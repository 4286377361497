var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LG } from 'big-l';
import { HTTPError } from '../../net/HTTPClient';
import { setError, setLoading, setModule, setUser } from './actions';
import AuthClient from './client';
import { AuthErrorType } from './types';
const LGR = LG.ns('AuthThunks');
export const module = (module) => dispatch => {
    dispatch(setModule(module));
    dispatch(load(module));
};
export const logout = () => () => __awaiter(void 0, void 0, void 0, function* () { return AuthClient.logout(); });
export const load = (module) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    // let module = getState().auth.module;
    LGR.debug('Load auth', module);
    dispatch(setLoading(true));
    const user = yield AuthClient.loadProfile(module);
    dispatch(setUser(user || undefined));
    dispatch(setLoading(false));
    return user;
});
export const login = (loginOrEmail, pass, stay = false) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const module = getState().auth.module;
    dispatch(setLoading(true));
    let user;
    try {
        user = yield AuthClient.login(loginOrEmail, pass, module, stay);
        dispatch(setUser(user));
    }
    catch (e) {
        LGR.debug('Error', e);
        if (e instanceof HTTPError) {
            LG.warn(e);
            switch (e.code) {
                case 401:
                    dispatch(setError(AuthErrorType.WRONG_CREDENTIALS));
                    break;
                case 403:
                    dispatch(setError(AuthErrorType.NOT_ALLOWED));
                    break;
            }
        }
        else {
            LG.error(e);
        }
    }
    dispatch(setLoading(false));
    return user;
});
export const error = (err) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setError(err));
});
