"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["UNKNOWN"] = "unknown";
    OrderStatus["DELETED"] = "deleted";
    OrderStatus["CANCELED"] = "canceled";
    OrderStatus["WAITING"] = "waiting";
    OrderStatus["WAITING_DELIVERY"] = "waiting_delivery";
    OrderStatus["WAITING_VALIDATION"] = "waiting_validation";
    OrderStatus["SENT"] = "sent";
    OrderStatus["INVOICE_INVALID"] = "invoice_invalid";
    OrderStatus["INVOICE_VALIDATED"] = "invoice_validated";
    OrderStatus["DELIVERY_RUNNING"] = "delivery_running";
    OrderStatus["PARTIAL_DELIVERY"] = "partial_delivery";
    OrderStatus["DELIVERED"] = "delivered";
    OrderStatus["RECEIVED"] = "received";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
