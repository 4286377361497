const CJSON_ID_KEY = '$cid$';
const arrayRegexp = new RegExp(`^\\[${CJSON_ID_KEY.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\]:(\\d*)$`);
const refRegexp = new RegExp(`^${CJSON_ID_KEY.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}:(\\d*)$`);
export class CJSON {
    static stringify(value, replacer, space) {
        let cjsoned = CJSON.toString(value);
        for (let oo of Object.values(this.__registry)) {
            delete oo[CJSON_ID_KEY];
        }
        this.__registry = {};
        if (replacer || space) {
            return JSON.stringify(JSON.parse(cjsoned), replacer, space);
        }
        return cjsoned;
    }
    static toString(o) {
        if (typeof o == 'object') {
            if (o[CJSON_ID_KEY])
                return JSON.stringify(`${CJSON_ID_KEY}:${o[CJSON_ID_KEY]}`);
            let cjsonId = ++this.__next_id;
            this.__registry[cjsonId] = o;
            o[CJSON_ID_KEY] = cjsonId;
            if (Array.isArray(o)) {
                return `["[${CJSON_ID_KEY}]:${cjsonId}",${o.map(v => CJSON.toString(v)).join(',')}]`;
            }
            else {
                let props = [];
                for (let k in o) {
                    props.push(`"${k}":${CJSON.toString(o[k])}`);
                }
                return `{${props.join(',')}}`;
            }
        }
        return JSON.stringify(o);
    }
    static parse(cjson) {
        let parsed = JSON.parse(cjson, (key, value) => this.reviver(key, value));
        let restored = this.restore(parsed);
        this.__registry = {};
        return restored;
    }
    static restore(o) {
        switch (typeof o) {
            case 'object':
                if (Array.isArray(o)) {
                    for (let i in o)
                        o[i] = this.restore(o[i]);
                }
                else {
                    if (o[CJSON_ID_KEY]) {
                        delete o[CJSON_ID_KEY];
                        for (let k in o) {
                            o[k] = this.restore(o[k]);
                        }
                    }
                }
                break;
            case 'string':
                let match = o.match(refRegexp);
                if (match)
                    return this.restore(this.__registry[parseInt(match[1], 10)]);
                break;
        }
        return o;
    }
    static reviver(key, value) {
        if (value[CJSON_ID_KEY])
            this.__registry[value[CJSON_ID_KEY]] = value;
        if (Array.isArray(value) && typeof value[0] == 'string') {
            let match = value[0].match(arrayRegexp);
            if (match) {
                this.__registry[parseInt(match[1], 10)] = value;
                value.shift();
            }
        }
        return value;
    }
}
CJSON.__next_id = 0;
CJSON.__registry = {};
