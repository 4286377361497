"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Katal = void 0;
var Data_1 = require("../Data");
var Katal;
(function (Katal) {
    var IO;
    (function (IO) {
        IO.Type = Data_1.BaseData.IO.Type;
        var Direction;
        (function (Direction) {
            Direction["IN"] = "in";
            Direction["OUT"] = "out";
        })(Direction = IO.Direction || (IO.Direction = {}));
    })(IO = Katal.IO || (Katal.IO = {}));
    var System;
    (function (System) {
        System.Type = Data_1.BaseData.System.Type;
    })(System = Katal.System || (Katal.System = {}));
})(Katal = exports.Katal || (exports.Katal = {}));
