"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Taro = void 0;
var Taro;
(function (Taro) {
    Taro.MODULE = "taro";
    var Capability;
    (function (Capability) {
        Capability["OPEN_LIST"] = "open_list";
        Capability["EDIT_LIST"] = "edit_list";
        Capability["SCHEDULE_LIST"] = "schedule_list";
        Capability["OPEN_COMPANY_LIST"] = "open_company_list";
        Capability["EDIT_COMPANY_LIST"] = "edit_company_list";
        Capability["SCHEDULE_COMPANY_LIST"] = "schedule_company_list";
    })(Capability = Taro.Capability || (Taro.Capability = {}));
})(Taro = exports.Taro || (exports.Taro = {}));
